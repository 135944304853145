import React from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import { Link } from 'react-router-dom';

const ReadOnlyOrgtems = ({org, agencyForClient}) => {
  return (
    <Form className="zuko-app-form form-readonly-info mt-3">
      <FormGroup className="form-group" controlId="sessionLimit">
        <Form.Label>Session Limit</Form.Label>
        <Form.Control type="number" readOnly value={org?.sessionLimit ?? ''} />
        <div className="input-feedback">The number of sessions that all of your {org?.type === 'Agency' ? 'clients' : 'forms'} can track during the contract period.</div>
        {agencyForClient &&
          <p className="mb-0 mt-1">To edit, go to <Link to={{
            pathname: `/organisations/${agencyForClient.uuid}/edit`,
            hash: '#sessionLimits'}}>Manage Session Limits</Link>.</p>}
      </FormGroup>

      {org?.replayEnabled &&
        <FormGroup className="form-group" controlId="replayEnabled">
          <div className="d-inline-flex align-items-center">
            <Form.Check type="checkbox" checked={org.replayEnabled} readOnly disabled/>
            <Form.Label className="mb-0 ms-2 pe-none" diabled>Session Replay Enabled</Form.Label>
          </div>
          <div className="input-feedback">With <i>Replay Code</i> installed on your forms, tracked sessions are recorded. You can then search and watch sessions in <Link to={`/session-replay`} target="_blank">Session Replay</Link>.</div>
        </FormGroup>}

      {org?.type !== 'AgencyClient' &&
        <FormGroup className="form-group" controlId="contractType">
          <Form.Label>Contract Type</Form.Label>
          <Form.Control type="text" readOnly value={org?.contractType || ''} />
        </FormGroup>}

      {org?.subscriptionStatus &&
        <FormGroup className="form-group" controlId="subscriptionStatus">
          <Form.Label>Subscription Status</Form.Label>
          <Form.Control type="text" readOnly value={org.subscriptionStatus || ''} />
        </FormGroup>}
    </Form>
  );
};

export default ReadOnlyOrgtems;
