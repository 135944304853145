import React, { useContext, useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import { VscChromeClose } from "react-icons/vsc";
import { FaInfoCircle } from "react-icons/fa";

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import NoFormsMsg from '../Components/NoFormsMsg';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import api from '../api';
import { usePrevious } from '../hooks';
import AlertsChartImg from '../images/AlertsChart.png';
import AlertsConfigImg from '../images/AlertsConfig.png';

import './Alerts.scss';

const Alerts = ({mixpanel}) => {
  const { currentUser, currentOrg, setCurrentOrg, formsGroupedByOrg } = useContext(AppContext);
  const history = useHistory();
  const {uuid: orgUuid} = useParams();

  const [orgsLoading, setOrgsLoading] = useState(false);
  const [orgsError, setOrgsError] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgsByUuid, setOrgsByUuid] = useState({});
  const [forms, setForms] = useState([]);
  const [formsLoading, setFormsLoading] = useState(true);
  const [formsError, setFormsError] = useState(null);
  const [alertsByFormUuid, setAlertsByFormUuid] = useState({});
  const [loadAlertsError, setLoadAlertsError] = useState(null);
  const [showInfo, setShowInfo] = useState(null);

  const prevSelectedOrg = usePrevious(selectedOrg);

  // Identify the mixpanel user on page load for any subsequent tracking
  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Load all available orgs for the user
  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        setOrgsLoading(true);
        const { data: { organisations } } = await api.get('/organisations');

        const orgsByUuid = {};
        for (const org of organisations) {
          if (!orgsByUuid.hasOwnProperty(org.uuid)) orgsByUuid[org.uuid] = org;
        }
        setOrgsByUuid(orgsByUuid);
        setOrgsLoading(false);
      } catch (e) {
        setOrgsError('Something went wrong fetching organisations.');
        setOrgsLoading(false);
      }
    };

    fetchOrgs();
  }, [currentUser]);

  // Once available organisations have loaded - select incoming
  useEffect(() => {
    if (!selectedOrg && Object.keys(orgsByUuid).length) {
      const incomingOrg = orgsByUuid[orgUuid];
      setSelectedOrg(incomingOrg);

      if (incomingOrg) {
        // Set the app-wide organisation
        if ((!currentOrg && incomingOrg) ||
        (currentOrg?.uuid && incomingOrg.uuid && (incomingOrg.uuid !== currentOrg.uuid))) setCurrentOrg(incomingOrg);
      }
    }
  }, [mixpanel, orgUuid, orgsByUuid, selectedOrg, currentOrg, setCurrentOrg]);

  // Org selected so send mixpanel page view
  useEffect(() => {
    if (selectedOrg) {
      mixpanel.track('Page View', { page: 'Alerts', ...(selectedOrg.name && selectedOrg.uuid && selectedOrg.contractType) && {
        'Organisation Name': selectedOrg.name,
        'Organisation Uuid': selectedOrg.uuid,
        'Organisation Contract Type': selectedOrg.contractType,
      }});
    }
  }, [mixpanel, selectedOrg]);

  // Load selected orgs alerts
  useEffect(() => {
    const loadAlertsPerForm = async () => {
      try {
        setAlertsByFormUuid({});
        const { data: { alerts } } = await api.get('alerts', {
          params: {
            organisationUuid: orgUuid,
          }
        });

        setAlertsByFormUuid(alerts.reduce((acc, alert) => {
          if (!acc[alert.form.uuid]) acc[alert.form.uuid] = [];
          acc[alert.form.uuid].push(alert);
          return acc;
        }, {}));
      } catch (e) {
        setLoadAlertsError('Oops, something went wrong fetching alerts. Please try again.');
      }
    };

    loadAlertsPerForm();
  }, [orgUuid]);

  // Once the organisation is selected, fetch the forms
  useEffect(() => {
    const loadForms = async () => {
      try {
        setFormsLoading(true);
        setFormsError(null);
        setForms([]);

        const { data: { forms } } = await api.get(`/organisations/${selectedOrg.uuid}/forms`);
        if (forms.length) setForms(forms);
        setFormsLoading(false);
      } catch (e) {
        setFormsLoading(false);
        setFormsError('Oops, something went wrong loading forms.');
      }
    };

    if (selectedOrg) loadForms();

  }, [selectedOrg]);

  // Selected organisation changed - so update app-wide
  useEffect(() => {
    if (prevSelectedOrg?.uuid && selectedOrg?.uuid && (prevSelectedOrg.uuid !== selectedOrg.uuid)) {
      setCurrentOrg(selectedOrg);
    }
  }, [prevSelectedOrg, selectedOrg, setCurrentOrg, formsGroupedByOrg]);

  const handleOrgChange = (org) => {
    setSelectedOrg(org);
    history.push(`/organisations/${org.uuid}/alerts`);
    mixpanel.track('Selected Organisation', { page: 'Alerts' });
  };

  return (
    <Container fluid className="page" id="alerts-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Alerts</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col md={3} className="pt-0 pb-2 mt-1" id="organisation-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5EC" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={Object.values(orgsByUuid).length ? Object.values(orgsByUuid).map((org) => ({...org, value: org.uuid, label: org.name})) : orgsError || (!orgsLoading && !Object.keys(orgsByUuid).length) ? [] :
                [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}]}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
              onChange={handleOrgChange}
              placeholder="Select an organisation..."
              value={selectedOrg && selectedOrg.name && {...selectedOrg, label: selectedOrg.name, value: selectedOrg.uuid}}
            />
          </Col>
          <Col md={5} className="pt-0 pb-2 mt-1 ps-0 d-flex align-items-center">
            {loadAlertsError && <p className="error-message m-0">{loadAlertsError}</p>}
          </Col>
        </Row>
      </div>
      <div className="main-content">
        {(!formsLoading && !formsError && !forms.length > 0) ?
          <NoFormsMsg mixpanel={mixpanel} page={'Alerts'}/> :
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'Alerts'}
                org={selectedOrg}
                messageContent={'Alerts'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0">
              <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
                {selectedOrg && selectedOrg.name && <h1 id="alerts-title">{`${selectedOrg.name} | Alerts`}</h1>}
              </Col>
              <Col className="p-0 text-end title-tooltips align-self-center">
                <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Alerts info', { page: 'Alerts' });}} title="How to use"/>
              </Col>
              <div className={`page-info-card ${showInfo === null ? 'd-none' : ''} ${showInfo ? 'open' : 'closed'}`}>
                <div className="card-contents">
                  <Row className="g-0 card-title-row">
                    <Col className="p-0">
                      <Card.Title as="h3">Alerts</Card.Title>
                    </Col>
                    <Col className="p-0 text-end card-tooltip">
                      <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to page"/>
                    </Col>
                  </Row>
                  <Row className="g-0 text-content">
                    <Col lg={7} className="ps-0">
                      <Card.Text className="mb-3 subtitle">You can choose to be alerted by email when there is a change in visitors completing your form, or a change in visitors abandoning your form.</Card.Text>
                      <Card.Text>Typically alerts are configured to inform you when there are low numbers of completions, or high numbers of abandoned sessions on your forms.</Card.Text>
                      <Card.Text>Start by adding an alert for one of your forms, and select the event type you want to be alerted about.</Card.Text>
                      <dl>
                        <dt>Abandon Count</dt>
                        <dd>An abandon event is counted when Zuko stops receiving interaction events for a session - if a visitor does not complete your form, or is inactive for 30 minutes, then this
                          becomes an abandoned session.</dd>
                        <dt>Completion Count</dt>
                        <dd>A completion event is counted when Zuko receives a <code>completion</code> event which is sent in the form's completion tracking code. You will have set this up to fire
                        only when the visitor successfully completes your form.</dd>
                        <dt>Abandon Rate</dt>
                        <dd>The % of visitors who started your form, but did not complete or remained inactive for 30 minutes.</dd>
                        <dt>Completion Rate</dt>
                        <dd>The % of visitors who started your form and then went on to complete.</dd>
                      </dl>
                      <Card.Text>The graph plots the hourly value for that metric over the selected time period to give you a reference point for choosing your threshold.</Card.Text>
                      <figure className="text-center img-wrapper">
                        <img id="Alerts-chart-Img" src={AlertsChartImg} alt="Alerts-chart" className="card-info-img"></img>
                      </figure>
                      <Card.Text>You then set the parameters for when the abandon or completion count for a form is higher or lower than average. When that threshold is reached you’ll receive an email alert.</Card.Text>
                      <Card.Text>Alerts can be sent at all hours of the day or during specific hours. Your form may experience low completion rates during the night time and so you may wish to choose to be alerted during business hours only.</Card.Text>
                      <figure className="text-center img-wrapper">
                        <img id="Alerts-config-Img" src={AlertsConfigImg} alt="Alerts-config" className="card-info-img"></img>
                      </figure>
                      <Card.Text>All email addresses subscribed will be sent a confirmation email and will need to opt in to receive the alerts they are subscribed to. Recipients can unsubscribe at any time through a link in each alerts email.</Card.Text>
                    </Col>
                    <Col lg={5}>
                      <div className="card-tip-box">
                        <h4 className="pb-3">How to use this and what to look for</h4>
                        <Card.Text>Setting up alerts for your forms provides an early warning sign of performance issues. You can catch unexpected spikes or dips in abandon or completion rates.</Card.Text>
                        <Card.Text>It can be useful to set up multiple alerts for the same form, either for different metrics, different thresholds and to send to different visitors.</Card.Text>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
            <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
              {formsLoading && <p>Loading...</p>}
              {formsError && <p className="text-center">{formsError}</p>}
              {forms && forms.length > 0 && forms.map((form) => (
                <Card key={`form-${form.uuid}-alerts`}>
                  <Card.Body className="pb-0">
                    <Row className="g-0 card-title-row">
                      <Col className="p-0">
                        <Card.Title as="h3">{form.label}</Card.Title>
                      </Col>
                    </Row>
                    {Object.keys(alertsByFormUuid).length > 0 && alertsByFormUuid[form.uuid] &&
                      <Row className="g-0 card-table">
                        <Table borderless hover>
                          <thead className="table-header">
                            <tr>
                              <th>Alert description</th>
                              <th>Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(alertsByFormUuid[form.uuid]).map((alert) => (
                              <tr onClick={() => history.push(`/alerts/${alert.uuid}/edit`)} key={`table-row-${alert.uuid}`}>
                                <td className={!alert.enabled ? 'alert-disabled' : ''}>{`${alert.event ? alert.event + ' count' : alert.metric.replace('_', ' ')} is ${alert.comparator === '>' ? 'more than' : alert.comparator === '<' && 'less than'} ${alert.threshold} per hour `}(<span id="alert-enabled-status">{alert.enabled ? 'Enabled' : 'Disabled'}</span>)</td>
                                <td><Link to={`/alerts/${alert.uuid}/edit`} data-testid={`edit-alert-${alert.uuid}`}>Edit</Link></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>}
                    <Row className="g-0 card-bottom-tools">
                      <Col className="p-0 d-flex justify-content-end">
                        <Link to={`/alerts/new?form[uuid]=${form.uuid}`}>
                          <Button className="pill-btn" data-testid={`add-alert-for-form-${form.uuid}`}>Add</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Col>
        }
      </div>
    </Container>
  );
};

export default Alerts;
