import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { HiExternalLink } from 'react-icons/hi';
import { VscCheck, VscInfo } from 'react-icons/vsc';
import MultiStepProgressBar from '../../Components/MultiStepProgressBar';

import Tooltip from 'react-bootstrap/Tooltip';
import { MdContentCopy } from 'react-icons/md';

import './FormTrackingCodeWizard.scss';

const FormTrackingCodeWizard = ({
  form,
}) => {
  const [wizardCurrentStep, setWizardCurrentStep] = useState(1);
  const [defaultInstallType, setDefaultInstallType] = useState('tag-manager');
  const [activityCopyMsg, setActivityCopyMsg] = useState();
  const [showActivityCopyMsg, setShowActivityCopyMsg] = useState(false);
  const [completionCopyMsg, setCompletionCopyMsg] = useState();
  const [showCompletionCopyMsg, setShowCompletionCopyMsg] = useState(false);
  const [replayCopyMsg, setReplayCopyMsg] = useState();
  const [showReplayCopyMsg, setShowReplayCopyMsg] = useState(false);
  const activityCode = form && `<script src="https://assets.zuko.io/js/v2/client.min.js"></script>
<script>Zuko.trackForm({target:document.body,slug:'${form.slug}'}).trackEvent(Zuko.FORM_VIEW_EVENT);</script>`;
  const completionCode = form && `<script src="https://assets.zuko.io/js/v2/client.min.js"></script>
<script>Zuko.trackForm({slug:'${form.slug}'}).trackEvent(Zuko.COMPLETION_EVENT);</script>`;
  const replayCode = form && `<script src="https://assets.zuko.io/replay/v1/record.min.js"></script>
<script>ZukoReplay.recordForm({slug:'${form.slug}'});</script>`;
  const replayEnabled = form?.organisation?.replayEnabled;

  const handleActivityCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(activityCode);
      setActivityCopyMsg('Code copied');
    } catch (e) {
      setActivityCopyMsg('Auto copy failed. Please try copying the code manually.');
    } finally {
      setShowActivityCopyMsg(true);
    }
  };

  const handleCompletionCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(completionCode);
      setCompletionCopyMsg('Code copied');
    } catch (e) {
      setCompletionCopyMsg('Auto copy failed. Please try copying the code manually.');
    } finally {
      setShowCompletionCopyMsg(true);
    }
  };

  const handleReplayCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(replayCode);
      setReplayCopyMsg('Code copied');
    } catch (e) {
      setReplayCopyMsg('Auto copy failed. Please try copying the code manually.');
    } finally {
      setShowReplayCopyMsg(true);
    }
  };

  const handleStepNumberClick = useCallback((number) => {
    setWizardCurrentStep(number);
  }, []);

  // Remove copy msgs after a short time
  useEffect(() => {
    if (showActivityCopyMsg) setTimeout(() => {setShowActivityCopyMsg(null);}, 2500);
    if (showCompletionCopyMsg) setTimeout(() => {setShowCompletionCopyMsg(null);}, 2500);
  },[showActivityCopyMsg, showCompletionCopyMsg]);

  return (
    <div className="d-flex flex-column col" id="tracking-code-wizard">
      {((replayEnabled && wizardCurrentStep < 5) || (!replayEnabled && wizardCurrentStep < 4)) &&
        <div className="mx-auto progress-bar-container">
          <MultiStepProgressBar
            currentStep={wizardCurrentStep}
            stepValues={replayEnabled ? [1,2,3,4] : [1,2,3]}
            stepNames={replayEnabled ? ['Activity Code', 'Replay Code', 'Completion Code', 'Check Data'] : ['Activity Code', 'Completion Code', 'Check Data']}
            onStepNumberClick={handleStepNumberClick}
          />
        </div>}
      {wizardCurrentStep === 1 &&
          <div className="pb-2 pt-4 d-flex flex-column px-4 flex-grow-1">
            <div className="mt-3 d-flex flex-column justify-items-center">
              <p className="card-tagline text-center">This activity tracking code will track <OverlayTrigger placement="top"
                overlay={
                  <Popover>
                    <Popover.Body>
                        Session-level data will give you total views and starters, as well as provide insight into session duration and segments.
                    </Popover.Body>
                  </Popover>}>
                <u className="text-more-info">session data</u>
              </OverlayTrigger> and <OverlayTrigger placement="top"
                overlay={
                  <Popover>
                    <Popover.Body>
                        Field-level data includes interactions such as clicks, keypresses, input changes, field returns and points of abandonment.
                    </Popover.Body>
                  </Popover>}>
                <u className="text-more-info">field interactions</u>
              </OverlayTrigger> for this form.</p>
              <Row className="g-0 pt-2 pb-1 justify-content-center">
                <Col className="codeExample p-0 col-auto">
                  <pre>{activityCode}</pre>
                </Col>
                <Col className="code-copy-icon p-0 col-auto">
                  <OverlayTrigger show={showActivityCopyMsg ? true : false}
                    onExited={() => setActivityCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                    overlay={
                      <Tooltip id="copy-text-tooltip">{activityCopyMsg}</Tooltip>}>
                    <Button variant="primary" className="copy-code-button" onClick={handleActivityCopyClick}><MdContentCopy size="20px" title="Copy activity tracking code"/> Copy</Button>
                  </OverlayTrigger>
                </Col>
              </Row>
              <p className="text-center">Note: The entire page will be tracked. If you only want to track part of the page that your form is on, you can provide a <a href="https://www.zuko.io/guides/how-to-find-the-zuko-target" target="_blank" rel="noopener noreferrer">target<HiExternalLink className="align-top"/></a>.</p>
            </div>
            <Tabs defaultActiveKey={defaultInstallType} className="mt-3" onSelect={(t) => setDefaultInstallType(t)}>
              <Tab eventKey="tag-manager" title="Install using a Tag Manager">
                <ul>
                  <li>Create a new tag and select a <i>Custom HTML</i> tag type.</li>
                  <li>Paste in this custom activity code.
                  </li>
                  <li>Trigger the tag when the form is loaded - typically this is on <i>Page View - Window Loaded</i>.</li>
                </ul>
                <p className="mt-3 mb-0">View the step-by-step guide to <a href="https://www.zuko.io/guides/setting-up-zuko-in-google-tag-manager" target="_blank" rel="noopener noreferrer">setting up in Google Tag Manager<HiExternalLink className="align-top"/></a>.</p>
              </Tab>
              <Tab eventKey="direct-html" title="Install directly into HTML">
                <ul>
                  <li>Add this custom activity code when the form is visible.</li>
                  <li>Place it after the form, typically it is best placed before the closing {`</body>`} tag.</li>
                </ul>
                <p className="mt-3 mb-0">View the <a href="https://docs.zuko.io/knowledge-base/installation/" target="_blank" rel="noopener noreferrer">installation documentation<HiExternalLink className="align-top"/></a>.</p>
              </Tab>
            </Tabs>
            <div className="d-flex justify-content-end actions-row">
              {replayEnabled ?
                <Button className="mx-1" onClick={() => setWizardCurrentStep(2)}>Next to Replay Code</Button> :
                <Button className="mx-1" onClick={() => setWizardCurrentStep(2)}>Next to Completion Code</Button>}
            </div>
          </div>}
      {(replayEnabled && wizardCurrentStep === 2) &&
          <div className="pb-2 pt-4 d-flex flex-column px-4 flex-grow-1">
            <div className="mt-3 d-flex flex-column justify-items-center">
              <Alert variant="outline-info" className="mb-3 mx-auto w-fit-content">
                <div className="alert-svg-icon my-auto"><VscInfo size="20px"/></div>
                <p className="alert-text m-0 ps-3 pe-0 py-0">Installing the Replay Code is optional - go straight to the Completion Code if you are only tracking analytics on this form.</p>
              </Alert>
              <p className="card-tagline text-center">This replay code will record <OverlayTrigger placement="top"
                overlay={
                  <Popover>
                    <Popover.Body>
                       Page-level interaction includes scrolls, mouse movements and visible errors that the visitor may have seen.
                    </Popover.Body>
                  </Popover>}>
                <u className="text-more-info">page interactions</u>
              </OverlayTrigger> made by visitors to your form.</p>
              <Row className="g-0 pt-2 pb-1 justify-content-center">
                <Col className="codeExample p-0 col-auto">
                  <pre>{replayCode}</pre>
                </Col>
                <Col className="code-copy-icon p-0 col-auto">
                  <OverlayTrigger show={showReplayCopyMsg ? true : false}
                    onExited={() => setReplayCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                    overlay={
                      <Tooltip id="copy-text-tooltip">{replayCopyMsg}</Tooltip>}>
                    <Button variant="primary" className="copy-code-button" onClick={handleReplayCopyClick}><MdContentCopy size="20px" title="Copy replay code"/> Copy</Button>
                  </OverlayTrigger>
                </Col>
              </Row>
              <p className="text-center">Note: The entire page will be recorded. If you want to block parts of the page from Zuko Replay, you can <a href="https://docs.zuko.io/knowledge-base/installation/adding-session-replay.html#privacy" target="_blank" rel="noopener noreferrer">add class names to sensitive element<HiExternalLink className="align-top"/></a>.</p>
              <Tabs defaultActiveKey={defaultInstallType} className="mt-3" onSelect={(t) => setDefaultInstallType(t)}>
                <Tab eventKey="tag-manager" title="Install using a Tag Manager">
                  <ul className="mb-0">
                    <li>You can paste this replay code into the Activity Code tag, below the activity code.</li>
                  </ul>
                  <p className="mb-0">Or,</p>
                  <ul className="mb-0">
                    <li>Create a new <i>Custom HTML</i> tag, paste in the replay code.</li>
                    <li>Trigger this tag when the form is loaded - typically this is on <i>Page View - Window Loaded</i>.</li>
                  </ul>
                  <p className="mt-3 mb-0">View the guide to <a href="https://www.zuko.io/guides/setting-up-session-replay" target="_blank" rel="noopener noreferrer">setting up Session Replay<HiExternalLink className="align-top"/></a>.</p>
                </Tab>
                <Tab eventKey="direct-html" title="Install directly into HTML">
                  <ul className="mb-0">
                    <li>Add this custom replay code when the form is visible.</li>
                    <li>Typically it is best placed before the closing {`</body>`} tag.</li>
                  </ul>
                  <p className="mt-3 mb-0">View the guide to <a href="https://www.zuko.io/guides/setting-up-session-replay" target="_blank" rel="noopener noreferrer">setting up Session Replay<HiExternalLink className="align-top"/></a>.</p>
                </Tab>
              </Tabs>
              <div className={`d-flex justify-content-between actions-row`}>
                <Button variant="outline-secondary" className="mx-1 cancel" onClick={() => setWizardCurrentStep(1)}>Back to Activity Code</Button>
                <Button className="mx-1" onClick={() => setWizardCurrentStep(3)}>Next to Completion Code</Button>
              </div>
            </div>
          </div>}
      {((replayEnabled && wizardCurrentStep === 3) || (!replayEnabled && wizardCurrentStep === 2)) &&
          <div className="pb-2 pt-4 d-flex flex-column px-4 flex-grow-1">
            <div className="mt-3 d-flex flex-column justify-items-center">
              <p className="card-tagline text-center">This completion code will track completions of your form; which gives you valuable Abandon and Completion data.</p>
              <Row className="g-0 pt-2 pb-1 justify-content-center mb-3">
                <Col className="codeExample p-0 col-auto">
                  <pre>{completionCode}</pre>
                </Col>
                <Col className="code-copy-icon p-0 col-auto">
                  <OverlayTrigger show={showCompletionCopyMsg ? true : false}
                    onExited={() => setCompletionCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                    overlay={
                      <Tooltip id="copy-text-tooltip">{completionCopyMsg}</Tooltip>}>
                    <Button variant="primary" className="copy-code-button" onClick={handleCompletionCopyClick}><MdContentCopy size="20px" title="Copy completion code"/> Copy</Button>
                  </OverlayTrigger>
                </Col>
              </Row>
              <Tabs defaultActiveKey={defaultInstallType} className="mt-3" onSelect={(t) => setDefaultInstallType(t)}>
                <Tab eventKey="tag-manager" title="Install using a Tag Manager">
                  <ul className="mb-0">
                    <li>Create a new tag and select a <i>Custom HTML</i> tag type.</li>
                    <li>Paste in the custom completion code.
                    </li>
                    <li>Trigger this tag when a visitor successfully submits the form.</li>
                  </ul>
                </Tab>
                <Tab eventKey="direct-html" title="Install directly into HTML">
                  <ul className="mb-0">
                    <li>Add this custom code when a visitor successfully submits the form. This could be on the 'thank you' page, for example.</li>
                    <li>Typically this is placed before the closing {`</body>`}, but it can be placed anywhere on the page.</li>
                  </ul>
                  <p className="mt-3 mb-0">View the <a href="https://docs.zuko.io/knowledge-base/installation/" target="_blank" rel="noopener noreferrer">installation documentation<HiExternalLink className="align-top"/></a>.</p>
                </Tab>
              </Tabs>
              <div className={`d-flex justify-content-between actions-row`}>
                {replayEnabled ? <>
                  <Button variant="outline-secondary" className="mx-1 cancel" onClick={() => setWizardCurrentStep(2)}>Back to Replay Code</Button>
                  <Button className="mx-1" onClick={() => setWizardCurrentStep(4)}>Next to Check Data</Button>
                </> : <>
                  <Button variant="outline-secondary" className="mx-1 cancel" onClick={() => setWizardCurrentStep(1)}>Back to Activity Code</Button>
                  <Button className="mx-1" onClick={() => setWizardCurrentStep(3)}>Next to Check Data</Button>
                </>}
              </div>
            </div>
          </div>}
      {((replayEnabled && wizardCurrentStep === 4) || (!replayEnabled && wizardCurrentStep === 3)) &&
          <div className="pb-2 pt-4 d-flex flex-column px-4 flex-grow-1">
            <div className="mt-3 d-flex flex-column justify-items-center">
              <Alert variant="outline-info" className="mb-3 mx-auto w-fit-content">
                <div className="alert-svg-icon my-auto"><VscInfo size="20px"/></div>
                <p className="alert-text m-0 ps-3 pe-0 py-0">Please make sure the code snippets are either live, or in a preview/staging environment.</p>
              </Alert>
              <p className="card-tagline text-center">After successfully installing the code, it is useful to check that your form is sending events to Zuko.</p>
              <p>We recommend that you test your form tracking by filling it in, interact with your key fields and then submit your form. Note: If you want to remove these 'test' sessions from your Zuko data, you can reset form data on the <Link to={`/forms/${form?.uuid}/edit`}>Details</Link> page.</p>
              <h4 className="">Steps to check your data:</h4>
              <ol className="further-info-content">
                <li className="mb-1">
                  <h5 className="list-title mb-0">View Zuko events being sent from your form</h5>
                Right click on the page your form is on, click Inspect, open the Network tab, filter on 'zuko', then check these <a href="https://www.zuko.io/guides/checking-your-code-installation" target="_blank" rel="noopener noreferrer">example events<HiExternalLink className="align-top"/></a>.</li>
                <li className="mb-1">
                  <h5 className="list-title mb-0">View events being received by Zuko</h5>
                  View real-time <OverlayTrigger placement="top" overlay={<Popover>
                    <Popover.Body>
                    A view event is counted when Zuko receives a <code>formView</code> event. This is sent in the form's activity tracking code.
                    </Popover.Body>
                  </Popover>}>
                    <u className="text-more-info">View</u>
                  </OverlayTrigger> and <OverlayTrigger placement="top" overlay={<Popover>
                    <Popover.Body>
                    A completion event is counted when Zuko receives a <code>completion</code> event. This is sent in the form's completion code.
                    </Popover.Body>
                  </Popover>}>
                    <u className="text-more-info">Completion</u>
                  </OverlayTrigger> events in the Zuko app on the <Link to="/live">Live Events</Link> page.</li>
                <li className="mb-1">
                  <h5 className="list-title mb-0">View sessions in Zuko reports</h5>
                  Typically, sessions are available in reports within 30 mins from a final event. If you have completed your form, your session will be available more quickly. After that, you can view your test session/s
                  in any of the reports, such as in <Link to="/form_aggregate">Form Data</Link> or review field-by-field in <Link to="/session_explorer">Session Explorer</Link>.</li>
              </ol>
              <p>If you haven't found an answer here or in the <a href="https://www.zuko.io/guides" target="_blank" rel="noopener noreferrer">guides<HiExternalLink className="align-top"/></a>, please
          use the in-app messaging, or email <a href="mailto:support@zuko.io">support@zuko.io</a> to contact us.</p>
              <div className={`d-flex justify-content-between`}>
                {replayEnabled ? <>
                  <Button variant="outline-secondary" className="mx-1 cancel" onClick={() => setWizardCurrentStep(3)}>Back to Completion Code</Button>
                  <Button className="mx-1" onClick={() => setWizardCurrentStep(5)}>Done</Button></> : <>
                  <Button variant="outline-secondary" className="mx-1 cancel" onClick={() => setWizardCurrentStep(2)}>Back to Completion Code</Button>
                  <Button className="mx-1" onClick={() => setWizardCurrentStep(4)}>Done</Button></>}
              </div>
            </div>
          </div>}
      {((replayEnabled && wizardCurrentStep === 5) || (!replayEnabled && wizardCurrentStep === 4)) &&
        <div className="pb-2 pt-0 d-flex flex-column px-4 flex-grow-1">
          <Alert variant="outline-success" className="mb-2">
            <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
            <p className="alert-text m-0 ps-3 pe-0 py-0">You've completed the installation steps.</p>
          </Alert>
          <p>If you wish to further customise your installation, check out <a href="https://www.zuko.io/guides/using-custom-events" target="_blank" rel="noopener noreferrer">Custom Events<HiExternalLink className="align-top"/></a> and <a href="https://www.zuko.io/guides/using-custom-attributes" target="_blank" rel="noopener noreferrer">Custom Attributes<HiExternalLink className="align-top"/></a>.</p>
          <div className={`d-flex justify-content-center`}>
            <Button className="mx-1" onClick={() => setWizardCurrentStep(1)}>Start Again</Button>
          </div>
        </div>}
    </div>);
};

export default FormTrackingCodeWizard;
