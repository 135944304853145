import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { VscChromeClose, VscWarning } from "react-icons/vsc";
import { FaInfoCircle, FaSpinner, FaCog } from "react-icons/fa";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Select, { components }  from 'react-select';
import { GrDocumentPdf, GrDocumentCsv } from 'react-icons/gr';
import { saveAs } from 'file-saver';

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import DatePicker from '../Components/DatePicker';
import PrintPageHeader from '../Components/PrintPageHeader';
import NoFormsMsg from '../Components/NoFormsMsg';
import AppAlerts from '../Components/AppAlerts';
import ScrollToTop from '../Components/ScrollToTop';
import FeedbackRow from '../Components/FeedbackRow';
import CopyUrlIcon from '../Components/CopyUrlIcon';
import SegmentComparisonFieldTable from './SegmentComparisonFieldTable';
import api from '../api';
import { usePrevious, useAppForms, useAppQuery } from '../hooks';
import { useFieldSegmentComparisons } from '../hooks/field_segment_comparisons';
import {
  formatFiltersForSelectDropDown,
  labelForField,
  formatFieldSegmentComparisonSelectOptions,
  formatCellToStr,
  formatCellToStrWithPercent,
  formatCellToSeconds,
  compileFieldComparisonQueryString,
} from '../utils';
import moment from 'moment-timezone';
import { generatePdfDownload } from '../helpers/pdf';

import './SegmentComparisonField.scss';
import MetricDropdown from '../images/Pages/FieldSegmentComparison/MetricDropdown.png';
import Forms from '../forms';

export const fieldSegmentComparisonMetrics = [
  {value: 'abandon_count', label: 'Abandon Count', formatter: formatCellToStr},
  {value: 'abandon_rate',label: 'Abandon Rate', formatter: formatCellToStrWithPercent},
  {value: 'pct_of_total_abandons', label: '% of Total Abandons', formatter: formatCellToStrWithPercent},
  {value: 'mean_rtf', label: 'Mean Returns', formatter: formatCellToStr},
  {value: 'mean_tif', label: 'Mean Time Spent', formatter: formatCellToSeconds},
  {value: 'sessions_interacted_with_field', label: 'Sessions Interacted', formatter: formatCellToStr},
];

const SegmentComparisonField = ({mixpanel}) => {
  const history = useHistory();
  const {
    comparisons,
    comparisonsLoading,
    comparisonsLoadingError,
    comparisonsLoaded,
  } = useFieldSegmentComparisons();
  const { currentUser, setQuery } = useContext(AppContext);
  const { formsGroupedByOrg, formsGroupedByFormUuid, formsLoading, formsLoadingError } = useAppForms();
  const { query } = useAppQuery();
  const { time, fieldSegmentComparison: { comparisonUuid, metric: metricValue } = {} } = query || {};

  const [comparison, setComparison] = useState();

  const [createNewComparisonError, setCreateNewComparisonError] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedComparisonName, setEditedComparisonName] = useState('');
  const [nameSaveError, setNameSaveError] = useState(null);
  const [isConfirmingDelete, setIsConfirmingDelete] =  useState(false);
  const [deleteComparisonError, setDeleteComparisonError] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [showProgressBar, setShowProgressBar] = useState(true);
  const [tableLoadingProgress, setTableLoadingProgress] = useState(20);
  const [tableError, setTableError] = useState(null);
  const [table, setTable] = useState();
  const [createNewSegmentError, setCreateNewSegmentError] = useState(null);
  const [deleteSegmentError, setDeleteSegmentError] = useState(null);
  const [reorderError, setReorderError] = useState(null);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersError, setFiltersError] = useState(false);
  const [saveEditedSegmentError, setSaveEditedSegmentError] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const [mainError, setMainError] = useState(null);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [exportError, setExportError] = useState(false);
  const [pdfRequested, setPdfRequested] = useState(false);
  const [csvRequested, setCsvRequested] = useState(false);

  const pageInfoAreaRef = useRef(null);
  const pageInfoIconAreaRef = useRef(null);
  const comparisonSelectRef = useRef(); // Reference to Select dropdown to be able to programatically close it once a new comparison is created

  const prevComparison = usePrevious(comparison);
  const prevMetricValue = usePrevious(metricValue);
  const prevTime = usePrevious(time);
  const prevTable = usePrevious(table);

  const metric = fieldSegmentComparisonMetrics.find(({value}) => value === metricValue);

  const firstColumns = useMemo(() => [
    {Header: 'Order',accessor: 'order', width: 60},
    {Header: 'Label',accessor: 'label', width: 260},
    {Header: 'HTML Tag Name', accessor: 'htmlTagName', width: 160},
    {Header: 'HTML Type', accessor: 'htmlType', width: 130},
    {Header: 'HTML Name', accessor: 'htmlName', width: 150},
    {Header: 'HTML ID', accessor: 'htmlId', width: 150},
    {Header: 'All Sessions', accessor: 'all', width: 150,  Cell: metric?.formatter},
  ], [metric?.formatter]);

  const segmentAsColumn = useCallback((segment) => ({
    ...segment,
    accessor: segment.uuid,
    width: 200,
    type: segment.type || 'segment',
    Cell: metric.formatter,
  }), [metric?.formatter]);

  const resetAllErrors = useCallback(() => {
    setMainError(null);
    setCreateNewComparisonError(null);
    setNameSaveError(null);
    setTableError(null);
    setCreateNewSegmentError(null);
    setDeleteSegmentError(null);
    setSaveEditedSegmentError(null);
    setReorderError(null);
  }, []);

  const fetchData = useCallback(async ({withProgress = false}) => {
    const progressID = (withProgress || showProgressBar) && setInterval(() => setTableLoadingProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setDataLoaded(false);
      resetAllErrors();
      setTableLoading(true);
      if (withProgress) setShowProgressBar(true);

      const { data: { values, fields, segments } } = await api.get('/visualisations/field_segment_comparison/segment_metrics', {
        params: {
          comparisonUuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          metric: metricValue,
        }
      });

      if (withProgress || showProgressBar) setTableLoadingProgress(100);

      const segmentColumns = (segments.length ?
        segments.filter(s => s !== 'all').map(segmentUuid => segmentAsColumn(comparison.fieldSegments.find(s => s.uuid === segmentUuid) || {uuid: segmentUuid})) : [])
        .concat(comparison.fieldSegments.filter(s => s.type === 'temp').map(s => segmentAsColumn(s))); // Add any temp segments to columns too

      setTable({
        data: fields
          .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
          .map((field, fieldIndex) => ({
            ...field,
            label: labelForField(field),
            ...segments.reduce((acc, segmentUuid, segmentIndex) => {
              acc[segmentUuid] = values[fieldIndex][segmentIndex];
              return acc;
            }, {})
          })),
        columns: [
          ...firstColumns,
          ...segmentColumns,
        ],
      });
      setDataLoaded(true);
    } catch (e) {
      if (withProgress || showProgressBar)  setTableLoadingProgress(100);
      setTableError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading segments. Please try again.');
    } finally {
      if (withProgress || showProgressBar)  {
        setShowProgressBar(false);
        setTableLoadingProgress(0);
        clearInterval(progressID);
      }
      setTableLoading(false);
    }
  },[comparisonUuid, time, metricValue,
    resetAllErrors,
    firstColumns, segmentAsColumn,
    showProgressBar,
    comparison?.fieldSegments,
  ]);

  const handleCreateNewComparison = useCallback(async ({formUuid}) => {
    try {
      resetAllErrors();
      const formComparisons = Object.values(comparisons.getByFormUuid(formUuid)?.comparisons || {});
      const name = formComparisons?.length ? `Untitled Comparison (${formComparisons.length + 1})` : 'First Comparison';
      const comparison = await comparisons.create({name, form: {uuid: formUuid}});
      setTable(null);
      comparisonSelectRef?.current?.blur(); // Force the comparison Select to close by initiating a blur event
      history.push(compileFieldComparisonQueryString({comparisonUuid: comparison.uuid}));
      mixpanel.track('Created Comparison', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setCreateNewComparisonError('Not logged in');
        break;
      case 404:
        setCreateNewComparisonError('Form not found');
        break;
      case 422:
        setCreateNewComparisonError((e?.response?.data?.errors || []).map((e) =>
          `Oops, something went wrong creating the comparison - ${e.message}`));
        break;
      default:
        setCreateNewComparisonError('Oops, something went wrong creating the new comparison. Please try again.');
      }
    }
  }, [history, resetAllErrors, mixpanel, comparisons]);

  const handleSaveName = async () => {
    try {
      resetAllErrors();
      await comparisons.update({...comparison, name: editedComparisonName});
      setIsEditingName(false);
      setComparison({...comparison, name: editedComparisonName});
      mixpanel.track('Comparison Name Updated', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setNameSaveError('Not logged in');
        break;
      case 404:
        setNameSaveError('Comparison not found');
        break;
      case 422:
        setNameSaveError((e?.response?.data?.errors || []).map((e) =>
          `Oops, something went wrong saving the name - ${e.message}`));
        break;
      default:
        setNameSaveError('Oops, something went wrong saving the name - please try again.');
      }
    } finally {
      setIsEditingName(false);
    }
  };

  const handleDeleteComparison = async () => {
    try {
      resetAllErrors();
      await comparisons.delete(comparison);
      setComparison(null);
      setTable(null);
      history.push('/field-segment-comparison');
      mixpanel.track('Deleted Comparison', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setDeleteComparisonError('Not logged in');
        break;
      case 404:
        setDeleteComparisonError('Comparison not found');
        break;
      case 422:
        setDeleteComparisonError((e?.response?.data?.errors || []).map((e) =>
          `Oops, something went wrong deleting the comparison - ${e.message}`));
        break;
      default:
        setDeleteComparisonError('Oops, something went wrong deleting the comparison - please try again.');
      }
    } finally {
      setIsConfirmingDelete(false);
    }
  };

  const handleCreateNewSegment = useCallback(async ({tempUuid, filters, order}) => {
    try {
      setDataLoaded(false);
      const { fieldSegments } = comparison;

      if (!fieldSegments.filter(s => s.type === 'segment').length) setShowProgressBar(true); // Show loading when it's the first segment being created

      resetAllErrors();

      const createdSegment = await comparisons.createSegment({
        comparison,
        filters: filters.reduce((acc, {key, label: value}) => {
          if (!acc.hasOwnProperty(key)) acc[key] = [];
          acc[key].push(value);
          return acc;
        }, {}),
        order,
      });

      createdSegment.type = 'segment'; // Reference point to know that this is now saved

      const newSegments = fieldSegments.map(s => s.uuid === tempUuid ? createdSegment : s); // Replace created segment in-situ
      setComparison((prev) => ({...prev, fieldSegments: newSegments}));

      mixpanel.track('Created Segment', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setCreateNewSegmentError('Not logged in');
        break;
      case 404:
        setCreateNewSegmentError('Comparison not found');
        break;
      case 422:
        setCreateNewSegmentError((e?.response?.data?.errors || []).map((e) =>
              `Oops, something went wrong creating the new segment - ${e.message}`));
        break;
      default:
        setCreateNewSegmentError('Oops, something went wrong creating the new segment - please try again.');
      }
    }
  },[comparison, resetAllErrors, mixpanel, comparisons]);

  const handleCreateTempSegment = useCallback(() => {
    setComparison(prev => {
      const prevSegments = prev.fieldSegments || [];
      const greatestOrder = prevSegments.filter(s => s.order)?.map(s => s.order).sort().pop();
      const newSegments = prevSegments.concat({
        uuid: `temp-${Math.random().toString(36).slice(-6)}`, // A table column needs a unique identifier
        fieldSegmentFilters: [],
        order: (greatestOrder && greatestOrder + 1) || prevSegments.length + 1,
        type: 'temp',
      });

      return {...prev, fieldSegments: newSegments};
    });
  }, []);

  const handleCancelTempSegment = useCallback(({uuid}) => {
    setComparison(prev => ({...prev, fieldSegments: prev.fieldSegments.filter(s => s.uuid !== uuid)}));
  }, []);

  const handleDeleteSegment = useCallback(async ({uuid: segmentUuid}) => {
    try {
      setDataLoaded(false);
      const { fieldSegments } = comparison;

      resetAllErrors();
      await comparisons.deleteSegment({comparison, segmentUuid});

      const newSegments = fieldSegments.filter(s => s.uuid !== segmentUuid);

      setComparison((prev) => ({...prev,
        fieldSegments: newSegments.length ? newSegments :
          [{ // Always show a segment ready to be saved
            uuid: `temp-${Math.random().toString(36).slice(-6)}`,
            fieldSegmentFilters: [],
            order: 1,
            type: 'temp',
          }]
      }));

      mixpanel.track('Deleted Segment', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setDeleteSegmentError('Not logged in');
        break;
      case 404:
        setDeleteSegmentError('Segment not found');
        break;
      case 422:
        setDeleteSegmentError((e?.response?.data?.errors || []).map((e) =>
              `Oops, something went wrong deleting the segment - ${e.message}`));
        break;
      default:
        setDeleteSegmentError('Oops, something went wrong deleting the segment - please try again.');
      }
    }
  }, [comparison, resetAllErrors, mixpanel, comparisons]);

  const handleReorderSegment = useCallback(async (segmentUuids) => {
    try {
      const { fieldSegments } = comparison;

      resetAllErrors();
      await comparisons.updateSegmentsOrder({comparison, segmentUuids});

      const newSegments = fieldSegments
        .filter(s => s.type === 'segment') // Only sort saved segments
        .sort((a,b) => segmentUuids.indexOf(a.uuid) - segmentUuids.indexOf(b.uuid));

      setComparison((prev) => ({...prev,
        fieldSegments: newSegments
          .concat(fieldSegments.filter(s => s.type !== 'segment')) // Add temp segments back onto the end
      }));

      mixpanel.track('Reordered Segments', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setReorderError('Not logged in');
        break;
      case 404:
        setReorderError('Segment not found');
        break;
      case 422:
        setReorderError((e?.response?.data?.errors || []).map((e) =>
                      `Oops, something went wrong re-ordering the segment - ${e.message}`));
        break;
      default:
        setReorderError('Oops, something went wrong re-ordering the segment - please try again.');
      }
    }
  }, [comparison, resetAllErrors, mixpanel, comparisons]);

  const handleSaveEditedSegment = useCallback(async ({uuid: segmentUuid, filters = [], order}) => {
    try {
      setDataLoaded(false);
      const { fieldSegments } = comparison;

      resetAllErrors();
      const savedSegment = await comparisons.updateSegment({
        comparison,
        segmentUuid,
        filters: filters.reduce((acc, {key, label, value}) => {
          if (!acc.hasOwnProperty(key)) acc[key] = [];
          acc[key].push(label || value);
          return acc;
        }, {}),
        order,
      });

      savedSegment.type = 'segment';

      const newSegments = fieldSegments.map(s => s.uuid === segmentUuid ? savedSegment : s);
      setComparison((prev) => ({...prev, fieldSegments: newSegments}));

      fetchData({withProgress: false});

      mixpanel.track('Edited Segment', { page: 'SegmentComparisonField' });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setSaveEditedSegmentError('Not logged in');
        break;
      case 404:
        setSaveEditedSegmentError('Segment not found');
        break;
      case 422:
        setSaveEditedSegmentError((e?.response?.data?.errors || []).map((e) =>
                  `Oops, something went wrong saving the segment - ${e.message}`));
        break;
      default:
        setSaveEditedSegmentError('Oops, something went wrong saving the segment - please try again.');
      }
    }
  },[comparison, fetchData, resetAllErrors, mixpanel, comparisons]);

  const handleComparisonChange = (changeToComparison) => {
    if (changeToComparison.uuid !== comparison?.uuid) {
      setDataLoaded(false);
      mixpanel.track('Selected Comparison', {page: 'SegmentComparisonField'});

      resetAllErrors();
      setTable(null);

      history.push(compileFieldComparisonQueryString({
        comparisonUuid: changeToComparison.uuid,
        time,
        metric: metricValue,
      }));
    }
  };

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'SegmentComparisonField',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
    });

    setTableLoading(true);
    history.push(compileFieldComparisonQueryString({
      comparisonUuid: comparison?.uuid,
      time: {start, end},
      metric: metricValue,
    }));
  };

  const handleSelectMetric = (e) => {
    mixpanel.track('Selected Metric', {page: 'SegmentComparisonField'});

    setTableLoading(true);
    history.push(compileFieldComparisonQueryString({
      comparisonUuid: comparison?.uuid,
      time,
      metric: e.value,
    }));
  };

  const handleCloseOverlay = (e) => {
    if (pageInfoIconAreaRef.current && !pageInfoIconAreaRef.current.contains(e.target) && pageInfoAreaRef.current && !pageInfoAreaRef.current.contains(e.target)) {
      setShowInfo(null);
    }
  };

  const comparisonFilterOption = ({ data: { orgName, orgUuid, formLabel, formUuid, label: comparisonLabel, value: comparisonUuid } }, currentSearchValue ) => (
    (comparisonLabel && typeof comparisonLabel === 'string' && comparisonLabel?.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase())) ||
    comparisonUuid?.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    orgUuid?.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    formLabel?.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    formUuid?.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    orgName?.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase())
  );

  const initiatePdfDownload = useCallback(async () => {
    try {
      setExportError(false);

      await generatePdfDownload({
        page: 'FieldSegmentComparison',
        title: 'Field Segment Comparison',
        comparison,
        time,
        queryString: compileFieldComparisonQueryString({comparisonUuid, metric: metricValue, time}),
        data: {
          metric,
          fieldSegmentTableRows: [...table.data].sort((a, b) => b.all - a.all),
          fieldSegments: table.columns.filter(({uuid}) => uuid),
        },
      });
    } catch (e) {
      setExportError(true);
    } finally {
      setPdfRequested(false);
    }
  }, [comparison, comparisonUuid, metric, metricValue, time, table?.data, table?.columns]);

  const handleCsvDownload = async () => {
    try {
      setExportError(false);
      const response = await api.get('/export/field_segment_comparison', {
        params: {
          uuid: comparison.uuid,
          timePeriod: {
            start: time?.start?.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time?.end?.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
          timeZone: comparison?.org?.timeZone,
          metric: metricValue,
        },
      });

      saveAs(new Blob([response.data]), `${comparison.form.label}_${comparison.name}_${metric.label}_${time.start.format('YYYY-MM-DD')}_${time.end.format('YYYY-MM-DD')}.csv`);
    } catch (e) {
      setExportError(true);
    } finally {
      setCsvRequested(false);
    }
  };

  // Identify the mixpanel user on page load for any subsequent tracking
  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Set the comparison for the page now that have the comparisonUuid
  useEffect(() => {
    if (comparisonUuid && comparisonsLoaded) {
      const selectedComparison = comparisons.getByUuid(comparisonUuid);
      if (!selectedComparison) setMainError('Comparison not found - please select or create one.');
      if (selectedComparison) {
        selectedComparison.fieldSegments = selectedComparison.fieldSegments?.map(s => ({...s, type: 'segment'})) || []; // Apply a type to know these are saved segments
        setComparison(selectedComparison);
      }
    }
  }, [comparisonUuid, comparisons, comparisonsLoaded]);

  // Mixpanel page view
  useEffect(() => {
    if (comparison?.form.uuid && formsGroupedByFormUuid?.[comparison.form.uuid]) {
      const {
        name: orgName,
        uuid: orgUuid,
        contractType
      } = formsGroupedByFormUuid[comparison.form.uuid].organisation;
      mixpanel.track('Page View', {
        page: 'SegmentComparisonField',
        'Organisation Name': orgName,
        'Organisation Uuid': orgUuid,
        'Organisation Contract Type': contractType,
      });
    } else {
      mixpanel.track('Page View', {page: 'SegmentComparisonField'});
    }
  }, [mixpanel, comparisonUuid, comparison, formsGroupedByFormUuid]);


  // Create a first comparison by default
  useEffect(() => {
    if (
      query?.fieldSegmentComparison && !comparisonUuid // Other query items set but missing a comparisonUuid
      && Forms.length // At least one form is needed
      && !comparison // A comparison hasn't been requested
      && comparisonsLoaded && !comparisons.length // There are no comparisons
    ) {
      (async () => {
        const firstForm = Object.values(await Forms.groupByUuid())[0];
        await handleCreateNewComparison({formUuid: firstForm?.uuid});
      })();
    }
  }, [comparison, query?.fieldSegmentComparison, comparisonUuid, handleCreateNewComparison, comparisonsLoaded,
    comparisons.length]);


  // Selected comparison has no semgents - so create a temp one
  useEffect(() => {
    if ((comparison && !comparison.hasOwnProperty('fieldSegments')) || (comparison?.fieldSegments && !comparison?.fieldSegments?.length)) {
      handleCreateTempSegment();
    }
  }, [comparison, handleCreateTempSegment]);

  // Comparison's form changed - so update app-wide
  useEffect(() => {
    if (comparison?.formUuid && formsGroupedByFormUuid[comparison.formUuid]) {
      setQuery((prevQuery) => ({...prevQuery, form: formsGroupedByFormUuid[comparison.formUuid]}));
    }
  }, [comparison?.formUuid, formsGroupedByFormUuid, setQuery]);

  // Segments changed - so provide the table with updated columns
  useEffect(() => {
    if ((comparison?.uuid === prevComparison?.uuid) && comparison?.fieldSegments && prevComparison?.fieldSegments && (comparison?.fieldSegments !== prevComparison?.fieldSegments)) {
      setTable((prev) => ({
        data: !comparison.fieldSegments.filter(s => s.type === 'segment').length ? [] : (prev?.data || []), // Remove the old data if there are no saved segments
        columns: (prev?.columns || firstColumns).filter(c => (c.type !== 'temp' && ((c.type === 'segment' && c.accessor === 'all') || c.type !== 'segment'))) // Keep previous columns and the 'all' segment in place
          .concat(comparison?.fieldSegments.map(segmentAsColumn).sort((a, b) => {
            const aOrder = a.order;
            const bOrder = b.order;
            return (aOrder > bOrder) - (aOrder < bOrder);
          })),
      }));
      if (tableLoading) setTableLoading(false);
    }
  }, [tableLoading, comparison?.uuid, prevComparison?.uuid, comparison?.fieldSegments, prevComparison?.fieldSegments, firstColumns, segmentAsColumn]);

  // A new segment has been added to the table - so refetch data
  useEffect(() => {
    const segmentCount = table?.columns?.filter(c => c.type === 'segment')?.length;
    const prevSegmentCount = prevTable?.columns?.filter(c => c.type === 'segment')?.length;

    if (segmentCount > prevSegmentCount) fetchData({withProgress: false});
  }, [table?.columns, prevTable?.columns, fetchData]);

  // Time or a comparison's form changes - so fetch filters
  useEffect(() => {
    if (comparison?.uuid && comparisonUuid && (comparisonUuid === comparison.uuid) && // Comparison updated in page state
      comparison?.form.uuid && time && (!availableFilters || !prevComparison || !prevTime ||
      (prevComparison?.form.uuid && (prevComparison.form.uuid !== comparison.form.uuid)) ||
      (prevTime && (JSON.stringify(time) !== JSON.stringify(prevTime)))
    )) (async () => {
      try {
        setFiltersLoading(true);
        setFiltersError(null);
        const { data: { attributes } } = await api.get(`/data/sessions/attributes`, {
          params: {
            formUuid: comparison.form.uuid,
            time: {
              start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            },
          },
        });
        setAvailableFilters(formatFiltersForSelectDropDown(attributes));
      } catch (e) {
        setFiltersError('Error when fetching attributes');
      } finally {
        setFiltersLoading(false);
      }
    })();
  }, [comparison, comparisonUuid, time, prevComparison, prevTime, availableFilters]);

  // Once a new query has been set - proceed to fetch data
  useEffect(() => {
    if (comparison?.uuid && comparisonUuid && (comparisonUuid === comparison.uuid) && // Comparison updated in page state
      comparison?.fieldSegments?.length && time?.start && time?.end && metricValue &&
        (!prevComparison || // Initial load when a comparison is requested
        (prevComparison?.uuid && (
          (comparison.uuid !== prevComparison.uuid) || // Comparison changed
          (((!prevComparison.fieldSegments || !prevComparison.fieldSegments?.length) || // When a first default segment is created
              (prevMetricValue && (metricValue !== prevMetricValue)) || // Metric changed
              (JSON.stringify(time) !== JSON.stringify(prevTime))) // Time changed
          )
        ))
        )) {
      (async () => {
        fetchData({withProgress: true});
      })();
    }
  }, [comparisonUuid, comparison, metricValue, time, prevComparison, prevMetricValue, prevTime, fetchData]);

  // Initiate pdf download if requested, and once data is ready
  useEffect(() => {
    if (dataLoaded && pdfRequested) initiatePdfDownload();
  }, [dataLoaded, pdfRequested, initiatePdfDownload]);

  return (
    <Container fluid className="page" id="field-segment-comparison-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Field Segment Comparison</title>
      </Helmet>
      <div className="nav-wrapper browser-only" onClick={handleCloseOverlay}>
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col className="col-md-auto col-sm-12 pt-0 pb-2 pe-md-1 mt-1 d-inline-flex" id="datepicker">
            <DatePicker
              startTime={time?.start}
              endTime={time?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={comparison?.org?.timeZone}
            />
          </Col>
          <Col md={3} className="pt-0 pb-2 px-md-1 mt-1" id="comparison-select">
            <Select
              styles={{
                groupHeading: (styles, state) => ({...styles,
                  color: '#3F4047',
                  textTransform: 'none',
                  fontSize: 14,
                  fontWeight: 500,
                }),
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  backgroundColor: state.selectProps?.value && (state.selectProps.value.value === state.value) ? '#E2E5EC' : null,
                  color: '#3F4047',
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null},
                  paddingLeft: 40,
                  ...state.data.level === 1 && {fontSize: 14, fontWeight: 500, paddingLeft: 20},
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}

              options={formatFieldSegmentComparisonSelectOptions({
                currentFieldSegmentComparisonOrgUuid: comparison?.org.uuid,
                fieldSegmentComparisonsLoading: comparisonsLoading,
                fieldSegmentComparisonsLoadingError: comparisonsLoadingError,
                fieldSegmentComparisonsLoaded: comparisonsLoaded,
                fieldSegmentComparisonsByFormUuid: comparisons.groupedByFormUuid(),
                formsGroupedByOrg: formsGroupedByOrg,
                handleCreateNewComparison,
              })}

              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
              onChange={handleComparisonChange}
              placeholder="Select a comparison..."
              value={comparison && comparison.uuid && comparison.name && (comparison.value ? comparison : {...comparison, value: comparison.uuid, label: comparison.name})}
              components={{
                Option: ({children, ...props}) => {
                  return (props.data.level === 1 && props.data.comparisonsCount) ? // It's a form option
                    <components.Option {...props}>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
                        <span>{children}</span>
                        <span style={{
                          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 14,
                          fontWeight: "normal", lineHeight: "1", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
                          {props.data.comparisonsCount}
                        </span>
                      </div>
                    </components.Option> :
                    <components.Option {...props}>
                      {children}
                    </components.Option>;
                }
              }}
              filterOption={comparisonsLoaded && comparisonFilterOption}
              ref={comparisonSelectRef}
            />
          </Col>
          <Col className="pt-0 pb-2 ps-md-1 mt-1 d-flex align-items-center">
            <Form.Label className="mb-0 me-1">Show field data for:</Form.Label>
            <Select
              id="field-metric-select"
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                singleValue: (styles, state) => ({...styles,
                  color: '#333333',
                }),
                option: (styles, state) => {
                  return {...styles,
                    color: '#3f4047',
                    backgroundColor: state.selectProps.value && (state.selectProps.value.value === state.value) ? "#E2E5Ec" : null,
                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                  };},
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                  zIndex: 5,
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={fieldSegmentComparisonMetrics}
              onChange={handleSelectMetric}
              placeholder="Select a metric..."
              value={metric}
            />
          </Col>
        </Row>
      </div>
      <ScrollToTop />
      <div className="main-content" onClick={handleCloseOverlay}>
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'SegmentComparisonField'}
                org={comparison?.org}
                messageContent={'Field Segment Comparison'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            {(deleteComparisonError || createNewSegmentError || saveEditedSegmentError || deleteSegmentError || createNewComparisonError || nameSaveError || reorderError) &&
              <Row className="alert-row g-0 error-alert">
                <Alert dismissible variant="danger" closeVariant="white" onClose={() => resetAllErrors()}>
                  <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                  <p className="alert-text m-0">{deleteComparisonError || createNewSegmentError || saveEditedSegmentError || deleteSegmentError || createNewComparisonError || nameSaveError || reorderError}</p>
                </Alert>
              </Row>
            }
            <Row className="title-row g-0 browser-only">
              <Col className={`p-0 original-content d-inline-flex align-items-center ${showInfo ? 'background' : ''}`}>
                {comparison &&
                <h1 id="comparison-title" className="d-inline-flex" data-testid="page-title">
                  {`${comparison?.form?.label} | `}
                  {!isEditingName && comparison?.name}
                  {isEditingName && <>
                    <Form.Control id="comparison-name" className={`d-inline-block ${(editedComparisonName.length < 1) ? 'invalid-input' : ''}`} type="text" value={editedComparisonName} required
                      maxLength={50} htmlSize={editedComparisonName.length + 2} onChange={({target: {value}}) => setEditedComparisonName(value)} />
                    <Button variant="outline-secondary" className="cancel me-1" onClick={() => setIsEditingName(false)}>Cancel</Button>
                    <Button className="load ms-1 me-0" onClick={handleSaveName}>Save</Button>
                  </>}
                  {isConfirmingDelete && <>
                    <p id="delete-confirmation" className="d-inline-block text-muted m-0 ps-2"><small>Are you sure?</small></p>
                    <span id="comparison-delete-button">
                      <Button variant="outline-secondary" className="cancel me-1" onClick={() => setIsConfirmingDelete(false)}>Cancel</Button>
                      <Button variant="outline-danger" className="ms-1 me-0" onClick={handleDeleteComparison}>Delete {comparison?.name}</Button>
                    </span>
                  </>}
                  {!isEditingName && !isConfirmingDelete && <>
                    <span className="comparison-icon" onClick={() => {setEditedComparisonName(comparison.name); setIsEditingName(true);}}><i className="fa fa-pencil editing"/></span>
                    <span className="comparison-icon" onClick={() => setIsConfirmingDelete(true)} data-testid="delete-comparison"><i className="fa fa-trash editing"/></span></>}
                </h1>
                }
              </Col>
              <Col className={`p-0 col-auto text-end title-tooltips align-self-center original-content ${showInfo ? 'background' : ''}`}>
                {comparison && <>
                  {exportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting data. Please try again.</p>}
                  <span className="icon-overlay-container">
                    <GrDocumentPdf size="20px" title="Export to PDF" className={`pdf-export-icon ${pdfRequested ? 'generating' : 'data-loaded'}`}
                      onClick={() => {setPdfRequested(true); mixpanel.track('Clicked to export', { page: 'SegmentComparison', exportType: 'PDF' });}}/>
                    {pdfRequested && <FaSpinner size="18px" className="spinning-icon" title="Generating PDF..."/>}
                  </span>
                  <span className="icon-overlay-container">
                    <GrDocumentCsv size="20px" title="Export to CSV" className={`csv-export-icon ${csvRequested ? 'generating' : 'data-loaded'}`}
                      onClick={() => {handleCsvDownload(); setCsvRequested(true); mixpanel.track('Clicked to export', { page: 'FieldSegmentComparison', exportType: 'CSV' });}}/>
                    {csvRequested && <FaSpinner size="18px" className="spinning-icon" title="Generating CSV..."/>}
                  </span>
                  <CopyUrlIcon
                    queryString={compileFieldComparisonQueryString({ comparisonUuid, metric: metricValue, time })}/>
                  <Link to={`/forms/${comparison.form.uuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </>}
                <span ref={pageInfoIconAreaRef}>
                  <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Segment Comparison info', { page: 'SegmentComparisonField' });}} title="How to use"/>
                </span>
              </Col>
              <div className={`page-info-card position-relative ${showInfo === null ? 'd-none' : ''} ${showInfo ? 'open' : 'closed'}`} ref={pageInfoAreaRef}>
                <div className="card-contents">
                  <Row className="g-0 card-title-row">
                    <Col className="p-0">
                      <Card.Title as="h3">Field Segment Comparison</Card.Title>
                    </Col>
                    <Col className="p-0 text-end card-tooltip">
                      <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)}  title="Return to page"/>
                    </Col>
                  </Row>
                  <Row className="g-0 text-content">
                    <Col lg={8} className="ps-0">
                      <Card.Text className="mb-3 subtitle">This is a flexible report to compare segments of traffic for a form's fields, side-by-side.</Card.Text>
                      <Card.Text>Start by picking a time frame, then define segments by selecting the relevant filters from the dropdown.</Card.Text>
                      <Card.Text>Several segment columns can be added, allowing the data to be viewed side-by-side.</Card.Text>
                      <Card.Text>The metric can be changed from the dropdown in the nav bar:</Card.Text>
                      <figure className="img-wrapper">
                        <img id="MetricDropdownImg" src={MetricDropdown} alt="Metrics that are available to be selected" className="card-info-img ms-3"/>
                      </figure>
                      <Card.Text>The metric definitions are:</Card.Text>
                      <dl>
                        <dt>Abandon Count</dt>
                        <dd>A count of how many times an abandonment occurs on each field.</dd>
                        <dt>Abandon Rate</dt>
                        <dd>The rate at which an abandonment occurs on this field.<br/>
                          This is calculated using the abandon count for this field compared to the amount of sessions
                          that interacted with it.</dd>
                        <dt>Percent of Total Abandons</dt>
                        <dd>The percentage of abandons on each field out of the total abandons on the form.</dd>
                        <dt>Mean Returns</dt>
                        <dd>The average (mean) amount of returns to each field.</dd>
                        <dt>Mean Time Spent</dt>
                        <dd>The average (mean) time that visitors spend interacting with each field.<br/>
                          This metric is in seconds.</dd>
                        <dt>Sessions Interacted</dt>
                        <dd>A count of sessions where the field was interacted with at least once.</dd>
                      </dl>
                    </Col>
                    <Col lg={4}>
                      <div className="card-tip-box">
                        <h4 className="pb-3">How to use this and what to look for</h4>
                        <Card.Text>This visualisation is particularly useful for comparing specific differences in how
                          audience segments interact with particular fields.</Card.Text>
                        <Card.Text>For example, you might compare how mobile visitors differ from desktop visitors. If a
                          particular field has a significantly higher abandon rate for mobile visitors than desktop, it may
                          indicate that there is an issue with how that field functions on mobile devices. You can then
                          investigate further to see if this is the case.</Card.Text>
                        <Card.Text>Similarly, if visitors of a particular browser return to a field at a much higher rate
                          than others (based on the Mean Returns metric) you can surmise they are having problems
                          successfully completing it and this may be linked to the type of browser.</Card.Text>
                        <Card.Text>The Sessions Interacted metric is also useful to check sample sizes of the data.
                          You’ll be able to see if an audience group is not interacting with certain fields for some
                          reason. This will allow you to identify whether those fields are not being shown to particular
                          device types / browsers / traffic sources.</Card.Text>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>

            <Col className="chart-content p-0">
              <PrintPageHeader
                pageTitle={'Field Segment Comparison'}
                orgName={comparison?.form?.uuid && formsGroupedByFormUuid?.[comparison.form.uuid].label} // Provide form label in place
                formLabel={comparison?.name} // Provide comparison name in place
                searchParams={comparisonUuid && compileFieldComparisonQueryString({ comparisonUuid, metric: metricValue, time })}
              />

              <Card id="segment-comparison">
                <Card.Body>
                  <Row className="g-0 card-title-row">
                    <Col className="p-0">
                      <Card.Title as="h3">Field Segment Comparison</Card.Title>
                    </Col>
                  </Row>
                  <div className="card-vis">
                    {(mainError || comparisonsLoadingError) && <p className="p-3 text-center">{mainError || comparisonsLoadingError}</p>}
                    {(!mainError && !comparisonsLoadingError) && <>
                      <div className={`pt-3 original-content ${showInfo ? 'background' : ''}`} id="segment-comparison-data">
                        <div id="scrollable-comparison-container">
                          <div id="fields-area" className="">
                            <SegmentComparisonFieldTable
                              tableError={tableError}
                              tableLoading={tableLoading}
                              tableLoadingProgress={tableLoadingProgress}
                              showProgressBar={showProgressBar}
                              columns={table?.columns || []}
                              data={table?.data || []}
                              filtersLoading={filtersLoading}
                              availableFilters={availableFilters}
                              filtersError={filtersError}
                              handleCreateNewSegment={handleCreateNewSegment}
                              handleDeleteSegment={handleDeleteSegment}
                              handleSaveEditedSegment={handleSaveEditedSegment}
                              handleReorderSegment={handleReorderSegment}
                              handleCreateTempSegment={handleCreateTempSegment}
                              handleCancelTempSegment={handleCancelTempSegment}
                              comparisonForm={comparison?.form}
                            />
                          </div>
                        </div>
                      </div>
                    </>}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'SegmentComparisonField'}/>
        }
      </div>
    </Container>
  );
};

export default SegmentComparisonField;
