import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import NavBar from '../NavBar';
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import AppContext from '../AppContext';
import api from '../api';
import qs from 'qs';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { VscWarning, VscCheck } from 'react-icons/vsc';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaSpinner, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { usePrevious } from '../hooks';
import passwordPolicy from '../password_policy';
import MultiStepProgressBar from '../Components/MultiStepProgressBar';

import './ProfileEdit.scss';

const validTabs = ['#details', '#security'];

const ProfileEdit = ({mixpanel, product = 'analytics'}) => {
  const { currentUser, setUser } = useContext(AppContext);
  const history = useHistory();
  const { search, hash, key: locationKey } = useLocation();
  const { path } = useRouteMatch();
  const searchParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
  const { userUuid: impersonatedUserUuid } = searchParams || {};
  const [impersonating, setImpersonating] = useState();
  const [impersonatedUser, setImpersonatedUser] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [weeklyEmailEnabled, setWeeklyEmailEnabled] = useState(false);
  const [apiKey, setApiKey] = useState();
  const [builderApiKey, setBuilderApiKey] = useState();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [newPasswordIsValid, setNewPasswordIsValid] = useState();
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState();
  const [currentPasswordIsValid, setCurrentPasswordIsValid] = useState();
  const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState();
  const [passwordUpdateErrorMsg, setPasswordUpdateErrorMsg] = useState(false);
  const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState();
  const [passwordUpdateAttempted, setPasswordUpdateAttempted] = useState();
  const [updateSuccessful, setUpdateSuccessful] = useState(null);
  const [updateRequested, setUpdateRequested] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mainError, setMainError] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);
  const [apiKeyShown, setApiKeyShown] = useState(false);
  const [apiKeyMsg, setApiKeyMsg] = useState();
  const [showApiKeyCopyMsg, setShowApiKeyCopyMsg] = useState(false);
  const [builderApiKeyLoading, setBuilderApiKeyLoading] = useState(true);
  const [builderApiKeyError, setBuilderApiKeyError] = useState(null);
  const [builderApiKeyShown, setBuilderApiKeyShown] = useState(false);
  const [builderApiKeyMsg, setBuilderApiKeyMsg] = useState();
  const [showBuilderApiKeyCopyMsg, setShowBuilderApiKeyCopyMsg] = useState(false);
  const [selectedTab, setSelectedTab] = useState(validTabs.includes(hash) ? hash : '#details');

  /// MFA
  const [showMfaSetupModal, setShowMfaSetupModal] = useState();
  const [mfaSetupCurrentStep, setMfaSetupCurrentStep] = useState(1);
  const [qrCode, setQrCode] = useState();
  const [qrCodeLoading, setQrCodeLoading] = useState();
  const [otp, setOtp] = useState();
  const [otpIsValid, setOtpIsValid] = useState();
  const [otpErrorMsg, setOtpErrorMsg] = useState();
  const [mfaMainErrorMsg, setMfaMainErrorMsg] = useState();
  const [showMfaDisableModal, setShowMfaDisableModal] = useState();
  const [mfaCurrentPassword, setMfaCurrentPassword] = useState('');
  const [mfaCurrentPasswordShown, setMfaCurrentPasswordShown] = useState(false);
  const [mfaCurrentPasswordIsValid, setMfaCurrentPasswordIsValid] = useState();
  const [mfaCurrentPasswordErrorMsg, setMfaCurrentPasswordErrorMsg] = useState();
  const [disableMfaComplete, setDisableMfaComplete] = useState();
  const [setupKey, setSetupKey] = useState();
  const [setupKeyLoading, setSetupKeyLoading] = useState();
  const [showManualSetupKey, setShowManualSetupKey] = useState();

  const prevCurrentUser = usePrevious(currentUser);
  const prevLocationKey = usePrevious(locationKey);
  const prevNewPassword = usePrevious(newPassword);

  const fetchApiKeyForUser = async (uuid) => {
    try {
      const { data: { apiKey } } = await api.get(`/users/${uuid}/api_key`);
      setApiKey(apiKey);
    } catch (e) {
      if (e.response && (e.response.status !== 404)) setApiKeyError(true);
    }
  };

  const fetchBuilderApiKeyForUser = async (uuid) => {
    try {
      setBuilderApiKeyLoading(true);
      const { data: { builderApiKey } } = await api.get(`/builder/users/${uuid}/api_key`);
      setBuilderApiKey(builderApiKey);
    } catch (e) {
      if (e.response && (e.response.status !== 404)) setBuilderApiKeyError(true);
    } finally {
      setBuilderApiKeyLoading(false);
    }
  };

  const handleGenerateBuilderApiKey = async (uuid) => {
    try {
      setBuilderApiKeyLoading(true);
      const { data: { builderApiKey } } = await api.post(`/builder/users/${uuid}/api_key`);
      setBuilderApiKey(builderApiKey);
    } catch (e) {
      setBuilderApiKeyError(true);
    } finally {
      setBuilderApiKeyLoading(false);
    }
  };

  const setFormToUser = useCallback((user) => {
    setName(user.name);
    setEmail(user.email);
    setWeeklyEmailEnabled(user.weeklyEmailEnabled);
    fetchApiKeyForUser(user.uuid);
    fetchBuilderApiKeyForUser(user.uuid);
  }, []);

  useEffect(() => {
    if (!impersonatedUserUuid && (!prevCurrentUser || impersonating)) {
      setFormToUser(currentUser);

      // An Account Manager has just impersonated and now goes to their own profile
      if (impersonating) setImpersonating(false);
      if (impersonatedUser) setImpersonatedUser(false);
    }
  }, [prevCurrentUser, impersonatedUserUuid, currentUser, impersonating, impersonatedUser, setFormToUser]);

  useEffect(() => {
    if (impersonatedUserUuid && !currentUser.accountManager) {
      history.replace('/profile');
      setFormToUser(currentUser);
    }

    if (impersonatedUserUuid && currentUser.accountManager) (async () => {
      try {
        const { data: { user } } = await api.get(`/users/${impersonatedUserUuid}`);
        setImpersonating(true);
        setImpersonatedUser(user);
        setFormToUser(user);
      } catch (e) {
        if (e.response.status === 403) { // Not expected but just in case
          history.replace('/profile');
          setFormToUser(currentUser);
        }
      }
    })();
  }, [currentUser, history, impersonatedUserUuid, setFormToUser]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', {page: 'ProfileEdit', product});
  }, [mixpanel, currentUser.email, product]);

  // To support back/forward navigation
  useEffect(() => {
    // If the hash has changed, select the tab
    if (!prevLocationKey || (prevLocationKey && (locationKey !== prevLocationKey))) {
      if (hash) {
        setSelectedTab(validTabs.includes(hash) ? hash : '#details');
      } else {
        setSelectedTab('#details');
      }
    }
  }, [locationKey, prevLocationKey, hash]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setApiKeyMsg('Copied!');
    } catch (e) {
      setApiKeyMsg('Sorry, failed to copy text to clipboard.');
    } finally {
      setShowApiKeyCopyMsg(true);
    }
  };

  const handleBuilderApiCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(builderApiKey);
      setBuilderApiKeyMsg('Copied!');
    } catch (e) {
      setBuilderApiKeyMsg('Sorry, failed to copy text to clipboard.');
    } finally {
      setShowBuilderApiKeyCopyMsg(true);
    }
  };

  // Remove copy msg after a short time
  useEffect(() => {
    if (showApiKeyCopyMsg) setTimeout(() => {setShowApiKeyCopyMsg(null);}, 2500);
    if (showBuilderApiKeyCopyMsg) setTimeout(() => {setShowBuilderApiKeyCopyMsg(null);}, 2500);
  },[showApiKeyCopyMsg, showBuilderApiKeyCopyMsg]);

  const handleSubmit = async (event) => {
    if (saveDisabled) return;
    setSaveDisabled(true);
    setErrorMessage(null);
    setMainError(null);
    setUpdateSuccessful(null);

    event.preventDefault();
    try {
      await api.put(`/users/${(impersonatedUser || currentUser).uuid}`, {
        user: {name, email, weeklyEmailEnabled},
      });
      setUpdateRequested(true);
      setUpdateSuccessful(true);

      if (!impersonating) {
        // Set the updated user details in context and storage so it's available during the user's session
        setUser({...currentUser, name, email, weeklyEmailEnabled});
      }

      mixpanel.track('Profile Updated', {page: 'ProfileEdit', product});
    } catch (e) {
      if (e.response && (e.response.status === 401)) {
        setMainError('Not logged in');
      } else if (e.response && (e.response.status === 422) && Object.keys(e.response.data.errors).length > 0) {
        setErrorMessage(e.response.data.errors.map(e => e.message));
      } else {
        setErrorMessage('Oops, something went wrong. The user profile has not been updated, please try again.');
      }
      setUpdateRequested(true);
      setUpdateSuccessful(false);
    } finally {
      setSaveDisabled(false);
    }
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) {
      if (!newPassword) validateNewPassword();
      return;
    }

    setPasswordUpdateErrorMsg(null);
    setPasswordUpdateSuccess(null);
    try {
      await api.put(`/users/${(impersonatedUser || currentUser).uuid}`, {currentPassword, newPassword});

      mixpanel.track('Password Updated', {page: 'ProfileEdit', product});
      setPasswordUpdateSuccess(true);
    } catch (e) {
      const defaultError = "We're sorry, something has gone wrong updating your password. Please try again.";

      if (e.response) {
        switch (e.response.status) {
        case 401:
          setMainError('Not logged in');
          break;
        case 400:
          if (e.response.data.errors && Object.keys(e.response.data.errors)?.length > 0) {
            const { currentPassword, newPassword } = e.response.data.errors;

            if (currentPassword) {
              setCurrentPasswordIsValid(false);
              setCurrentPasswordErrorMsg(currentPassword.map((e) => e.includes('is wrong') ? 'This current password does not match, please try again. Or <a href="/password-reset">reset your password.</a>' : `${e}`).join(','));
            }

            if (newPassword) {
              setNewPasswordIsValid(false);
              setNewPasswordErrorMsg(newPassword.map((e) => `${e}`).join(','));
            }
          } else {
            setPasswordUpdateErrorMsg(defaultError);
          }
          break;
        default:
          setPasswordUpdateErrorMsg(defaultError);
        }
      } else {
        setPasswordUpdateErrorMsg(defaultError);
      }
    }
  };

  const validateNewPassword = useCallback(() => {
    if (!newPassword) {
      setNewPasswordIsValid(false);
      setNewPasswordErrorMsg('Please enter a new password if you wish to change it.');
      return;
    }

    if (newPassword?.length < passwordPolicy.length.min) {
      setNewPasswordIsValid(false);
      setNewPasswordErrorMsg(`Your password needs to be a minimum of ${passwordPolicy.length.min} characters.`);
      return;
    }

    if (newPassword?.length > passwordPolicy.length.max) {
      setNewPasswordIsValid(false);
      setNewPasswordErrorMsg(`Your password needs to be no more than ${passwordPolicy.length.max} characters.`);
      return;
    }

    setNewPasswordIsValid(true);
  },[newPassword]);

  useEffect(() => {
    if (((!prevNewPassword || prevNewPassword?.length < 2) && newPassword?.length > 2) || // expected autofill
        (prevNewPassword?.length && !newPassword?.length) || // removed value
        (newPassword?.length >= passwordPolicy.length.min && newPassword?.length <= passwordPolicy.length.max) || // long enough
        (newPassword?.length > passwordPolicy.length.max) // too long
    ) validateNewPassword();
  }, [newPassword, prevNewPassword, validateNewPassword]);

  // Fetch QR code as soon as setup starts
  useEffect(() => {
    if (showMfaSetupModal) {
      (async () => {
        try {
          setQrCodeLoading(true);
          const { data } = await api.get(`/users/${currentUser.uuid}/mfa/totp/qr_code`, {
            responseType: 'blob',
            transformResponse:[(data) => data],
          });
          setQrCode(URL.createObjectURL(data));
        } catch (e) {
          const defaultError = "We're sorry something has gone wrong generating your QR code. Please try again.";

          if (e.response) {
            switch (e.response.status) {
            case 401:
              setMainError('Not logged in');
              handleCancelMfaSetup();
              break;
            case 404:
              setMfaMainErrorMsg("Two-factor authentication is already setup. Please contact <a href='mailto:support@zuko.io'>Zuko support</a> if you need further assistance.");
              handleCancelMfaSetup();
              break;
            default:
              setMfaMainErrorMsg(defaultError);
              handleCancelMfaSetup();
            }
          } else {
            setMfaMainErrorMsg(defaultError);
            handleCancelMfaSetup();
          }
        } finally {
          setQrCodeLoading(false);
        }
      })();
    }
  }, [showMfaSetupModal, currentUser.uuid]);

  // Fetch setup key once Qr code has been generated
  useEffect(() => {
    if (qrCode) {
      (async () => {
        try {
          setSetupKeyLoading(true);
          const { data: { setupKey } } = await api.get(`/users/${currentUser.uuid}/mfa/totp/setup_key`);
          setSetupKey(setupKey);
        } catch (e) {
          const defaultError = "We're sorry something has gone wrong retrieving your setup key. Please try again.";

          if (e.response) {
            switch (e.response.status) {
            case 401:
              setMainError('Not logged in');
              handleCancelMfaSetup();
              break;
            default:
              setMfaMainErrorMsg(defaultError);
              handleCancelMfaSetup();
            }
          } else {
            setMfaMainErrorMsg(defaultError);
            handleCancelMfaSetup();
          }
        } finally {
          setSetupKeyLoading(false);
        }
      })();
    }
  }, [qrCode, currentUser.uuid]);

  const handleCancelMfaSetup = () => {
    setShowMfaSetupModal(false);
    setMfaSetupCurrentStep(1);
    setQrCode(null);
    setOtp(null);
    setOtpIsValid(null);
    setOtpErrorMsg(null);
    setShowManualSetupKey(false);
  };

  const handleEnableMfa = async (e) => {
    e.preventDefault();

    if (!otp) {
      setOtpIsValid(false);
      setOtpErrorMsg('Please enter the code displayed in your app.');
    }

    if (otp && otp.length < 6) {
      setOtpIsValid(false);
      setOtpErrorMsg('Please enter all six numbers from the code displayed in your app.');
    }

    if (otp && otp.length === 6) {
      try {
        setOtpErrorMsg(null);
        await api.post(`/users/${currentUser.uuid}/mfa/totp/verify`, {otp});
        setUser({...currentUser, mfaEnabled: true});
        setMfaSetupCurrentStep(3);
        setDisableMfaComplete(false); // Remove any trace of having previously disabled
        mixpanel.track('MFA Enabled', {page: 'ProfileEdit', product});
      } catch (e) {
        const defaultError = "We're sorry, something has gone wrong enabling your two-factor authentication. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.";
        if (e.response) {
          switch (e.response.status) {
          case 401:
            setMainError('Not logged in');
            handleCancelMfaSetup();
            break;
          case 400:
            setMfaMainErrorMsg(defaultError);
            handleCancelMfaSetup();
            break;
          case 404:
            setOtpIsValid(false);
            setOtpErrorMsg("We're sorry this code is invalid. Please try again.");
            setOtp(null);
            break;
          default:
            setMfaMainErrorMsg(defaultError);
            handleCancelMfaSetup();
          }
        } else {
          setMfaMainErrorMsg(defaultError);
          handleCancelMfaSetup();
        }
      }
    }
  };

  const handleCancelMfaDisable = () => {
    setShowMfaDisableModal(false);
    setMfaCurrentPassword(null);
    setMfaCurrentPasswordShown(false);
    setMfaCurrentPasswordIsValid(null);
    setMfaCurrentPasswordErrorMsg(null);
  };

  const handleDisableMfa = async (e) => {
    e.preventDefault();

    if (!mfaCurrentPassword) {
      setMfaCurrentPasswordIsValid(false);
      setMfaCurrentPasswordErrorMsg('Please enter your current password to continue.');
      return;
    }

    if (mfaCurrentPassword) {
      try {
        setMfaCurrentPasswordErrorMsg(null);
        await api.post('/login', { email: currentUser.email, password: mfaCurrentPassword});
      } catch (e) {
        const defaultError = 'Something went wrong validating your password. Please try again and <a href="mailto: support@zuko.io">get in touch</a> if you need more help.';

        if (e.response) {
          switch (e.response.status) {
          case 401:
            setMainError('Not logged in');
            handleCancelMfaDisable();

            // Redirect was blocked in the api interceptor, so we do it here
            localStorage.clear();
            window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;
            break;
          case 403:
          case 404:
            setMfaCurrentPasswordIsValid(false);
            setMfaCurrentPasswordErrorMsg('This current password is invalid, please try again. Or <a href="/password-reset">reset your password.</a>');
            break;
          default:
            setMfaMainErrorMsg(defaultError);
            handleCancelMfaDisable();
          }
        } else {
          setMfaMainErrorMsg(defaultError);
          handleCancelMfaDisable();
        }
        return;
      }
    }

    try {
      setMfaCurrentPasswordErrorMsg(null);
      await api.delete(`/users/${currentUser.uuid}/mfa`);
      setUser({...currentUser, mfaEnabled: false});
      handleCancelMfaDisable();
      setDisableMfaComplete(true);
      mixpanel.track('MFA Disabled', {page: 'ProfileEdit', product});
    } catch (e) {
      const defaultError = "We're sorry, something has gone wrong disabling your two-factor authentication. Please try again or contact <a href='mailto:support@zuko.io'>Zuko support</a>.";

      if (e.response) {
        switch (e.response.status) {
        case 401:
          setMainError('Not logged in');
          handleCancelMfaDisable();
          break;
        case 404:
          setMfaMainErrorMsg("Two-factor authentication not found for this user. Please contact <a href='mailto:support@zuko.io'>Zuko support</a> for further assistance.");
          handleCancelMfaDisable();
          break;
        default:
          setMfaMainErrorMsg(defaultError);
          handleCancelMfaDisable();
        }
      } else {
        setMfaMainErrorMsg(defaultError);
        handleCancelMfaDisable();
      }
    }
  };

  return (
    <Container fluid className="profile-edit page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>{impersonatedUser ? `${impersonatedUser.name || 'User'}'s` : 'Your'} Profile</title>
      </Helmet>
      {product !== 'builder' &&
        <div className="nav-wrapper">
          <NavBar mixpanel={mixpanel}/>
        </div>}
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'ProfileEdit'}
            org={null}
            messageContent={'your profile'} />
          <AppAlerts />
          <Row className="title-row g-0">
          </Row>
          <Card id="profile-edit-card">
            <Card.Body>
              <TabContainer activeKey={selectedTab} onSelect={(t) => {
                history.push(`${path}${search}${t}`);
                mixpanel.track('Changed Tab', { page: 'ProfileEdit', product, tab: `${t}` });
                if (t !== '#security') {
                  setCurrentPassword('');
                  setNewPassword('');
                }
                if ((updateRequested && updateSuccessful) || (updateRequested && !updateSuccessful && errorMessage)) {
                  setUpdateRequested(null);
                  setUpdateSuccessful(null);
                }
                if (currentPasswordErrorMsg) setCurrentPasswordErrorMsg(null);
                if (newPasswordErrorMsg) setNewPasswordErrorMsg(null);
                if (!currentPasswordIsValid) setCurrentPasswordIsValid(null);
                if (!newPasswordIsValid) setNewPasswordIsValid(null);
                if (passwordUpdateAttempted) setPasswordUpdateAttempted(null);
                if (passwordUpdateErrorMsg) setPasswordUpdateErrorMsg(null);
                if (passwordUpdateSuccess) setPasswordUpdateSuccess(null);
                if (mfaMainErrorMsg) setMfaMainErrorMsg(null);
                if (disableMfaComplete) setDisableMfaComplete(null);
              }}>
                <Row className="g-0 card-title-row justify-content-between">
                  <Col className="p-0 col-auto">
                    <Card.Title as="h3">{impersonatedUser ? `${impersonatedUser.name || 'User'}'s` : 'Your'} Profile</Card.Title>
                  </Col>
                  <Col className="p-0 col-auto">
                    <Nav variant="tabs" className="justify-content-end" id="card-header-nav-tabs" activeKey={selectedTab}>
                      <Nav.Item>
                        <Nav.Link eventKey="#details">Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#security" data-testid="security-tab">Security</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>

                <Row className="g-0 card-content">
                  <TabContent className="w-100">
                    {mainError && <h3>{mainError}</h3>}
                    <TabPane eventKey="#details">
                      {updateRequested && updateSuccessful &&
                     <Row className="alert-row g-0 w-100">
                       <Alert dismissible variant="success" closeVariant="white"
                         onClose={() => {setUpdateRequested(null); setUpdateSuccessful(null);}}>
                         <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                         <p className="alert-text m-0">Profile updated.</p>
                       </Alert>
                     </Row>}
                      {updateRequested && !updateSuccessful && errorMessage &&
                    <Row className="alert-row g-0 w-100">
                      <Alert dismissible variant="danger" closeVariant="white"
                        onClose={() => {setUpdateRequested(null); setUpdateSuccessful(null);}}>
                        <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                        <p className="alert-text m-0">{errorMessage}</p>
                      </Alert>
                    </Row>}
                      {!mainError &&
                  <Form onSubmit={handleSubmit} className="zuko-app-form" id="form-details-form">
                    <FormGroup className="form-group" controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control name="name" type="text" placeholder="Enter your name" value={name || ''}
                        onChange={({target: {value}}) => {setName(value); setUpdateRequested(false);}} required/>
                    </FormGroup>

                    <FormGroup className="form-group" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control name="email" type="email" placeholder="you@example.com" value={email || ''}
                        onChange={({target: {value}}) => {setEmail(value); setUpdateRequested(false);}} required/>
                    </FormGroup>

                    {product !== 'builder' && <FormGroup className="form-group pt-1" controlId="weeklyEmail">
                      <div className="d-inline-flex align-items-center">
                        <Form.Check type="checkbox" checked={weeklyEmailEnabled}
                          onChange={(e) => {
                            setWeeklyEmailEnabled(e.target.checked);
                            setUpdateRequested(false);
                          }}/>
                        <Form.Label className="mb-0 ms-2">Analytics Weekly Email</Form.Label>
                      </div>
                      <div className="input-feedback">Receive a weekly update email (sent on a Monday at midday UTC) with highlights on all your forms.</div>
                    </FormGroup>}

                    {product !== 'builder' && (apiKey || apiKeyError) &&
                      <FormGroup className="form-group" controlId="apiKey">
                        <Form.Label>Analytics Sessions API Key</Form.Label>
                        {!apiKeyError &&
                            <InputGroup>
                              <Form.Control type={apiKeyShown ? 'text' : 'password'} name="apiKey" defaultValue={apiKey} readOnly
                                className={`api-key-text ${!apiKeyShown ? 'api-key-hidden' : ''}`} />
                              <OverlayTrigger show={showApiKeyCopyMsg ? true : false}
                                onExited={() => setApiKeyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                                overlay={
                                  <Tooltip id="copy-text-tooltip">{apiKeyMsg}</Tooltip>}>
                                <InputGroup.Text className="api-key-copy-container" onClick={handleCopyClick}>
                                  <MdContentCopy size="20px" className="grey-icon" title="Copy API key"/>
                                </InputGroup.Text>
                              </OverlayTrigger>
                              <InputGroup.Text className="password-show-icon-container" onClick={() => setApiKeyShown(!apiKeyShown)} data-testid="api-key-toggle">
                                {apiKeyShown ? <AiOutlineEye size="20px" className="grey-icon" title="API key visible"/> :
                                  <AiOutlineEyeInvisible size="20px" className="grey-icon" title="API key hidden"/>}
                              </InputGroup.Text>
                            </InputGroup>}
                        {apiKeyError && <p className="mb-0">We're sorry there was an error fetching the API key. Please try again.</p>}
                        <div className="input-feedback">You can use your unique API Key to access data via the Zuko Sessions API.</div>
                      </FormGroup>}

                    {product === 'builder' &&
                      <FormGroup className="form-group align-items-center" controlId="builderApiKey">
                        <Form.Label>Builder API Key</Form.Label>
                        {builderApiKeyLoading && <div><FaSpinner size="20px" className="spinning-icon me-2"/></div>}
                        {!builderApiKeyLoading && !builderApiKey && !builderApiKeyError &&
                            <div><Button className="ms-0"
                              onClick={() => handleGenerateBuilderApiKey(currentUser.uuid)}
                              disabled={impersonatedUserUuid && currentUser.accountManager}
                            >Generate API Key</Button></div>}
                        {!builderApiKeyError && builderApiKey &&
                              <InputGroup>
                                <Form.Control type={builderApiKeyShown ? 'text' : 'password'} name="apiKey" defaultValue={builderApiKey} readOnly
                                  className={`api-key-text ${!builderApiKeyShown ? 'api-key-hidden' : ''}`} />
                                <OverlayTrigger show={showBuilderApiKeyCopyMsg ? true : false}
                                  onExited={() => setBuilderApiKeyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                                  overlay={
                                    <Tooltip id="copy-text-tooltip" style={{position:"fixed"}}>{builderApiKeyMsg}</Tooltip>}>
                                  <InputGroup.Text className="api-key-copy-container" onClick={handleBuilderApiCopyClick}>
                                    <MdContentCopy size="20px" className="grey-icon" title="Copy API key"/>
                                  </InputGroup.Text>
                                </OverlayTrigger>
                                <InputGroup.Text className="password-show-icon-container" onClick={() => setBuilderApiKeyShown(!builderApiKeyShown)}>
                                  {builderApiKeyShown ? <AiOutlineEye size="20px" className="grey-icon" title="API key visible"/> :
                                    <AiOutlineEyeInvisible size="20px" className="grey-icon" title="API key hidden"/>}
                                </InputGroup.Text>
                              </InputGroup>}
                        {builderApiKeyError && <p className="mb-0">We're sorry there was an error fetching the API key. Please try again.</p>}
                        <div className="input-feedback">You can use your unique API Key in Zuko Form Builder integrations.</div>
                      </FormGroup>}

                    <Row className="form-actions-row">
                      <Col className="p-0">
                        <Button variant="primary" type="submit" className="submit ms-0" disabled={saveDisabled}>Save</Button>
                      </Col>
                    </Row>
                  </Form>}
                    </TabPane>
                    <TabPane eventKey="#security">
                      {!mainError &&
                    <Form noValidate onSubmit={handlePasswordUpdate} className="zuko-app-form change-password">
                      {passwordUpdateSuccess &&
                     <Row className="alert-row g-0">
                       <Alert dismissible variant="success" closeVariant="white"
                         onClose={() => setPasswordUpdateSuccess(false)}>
                         <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                         <p className="alert-text m-0">Password updated. You will need to use this new password the next time you log in.</p>
                       </Alert>
                     </Row>}
                      {passwordUpdateErrorMsg &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setPasswordUpdateErrorMsg(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                            <p className="alert-text m-0">{passwordUpdateErrorMsg}</p>
                          </Alert>
                        </Row>
                      }
                      <Row className="form-title-row">
                        <Col className="p-0">
                          <h3>Change Password</h3>
                        </Col>
                      </Row>
                      <FormGroup className="form-group" controlId="currentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <InputGroup>
                          <Form.Control type={currentPasswordShown ? 'text' : 'password'} placeholder="Enter your current password" name="current-password" value={currentPassword || ''} autoComplete="current-password"
                            className={((passwordUpdateAttempted && !currentPassword) || (currentPasswordIsValid === false)) ? 'invalid-input' : ''}
                            onChange={({target: {value}}) => {
                              setCurrentPassword(value);
                              if (currentPasswordIsValid === false) {
                                setCurrentPasswordIsValid(null);
                                setCurrentPasswordErrorMsg(null);
                              }
                            }} required/>
                          <InputGroup.Text className="password-show-icon-container" onClick={() => setCurrentPasswordShown(!currentPasswordShown)}>
                            {currentPasswordShown ? <AiOutlineEye size="20px" className="grey-icon" title="Current password visible"/> :
                              <AiOutlineEyeInvisible size="20px" className="grey-icon" title="Current password hidden"/>}
                          </InputGroup.Text>
                        </InputGroup>
                        {(passwordUpdateAttempted && !currentPassword) && <Form.Control.Feedback type="invalid" className="feedback-row">Please enter your current password.</Form.Control.Feedback>}
                        {(currentPasswordIsValid === false) && <Form.Control.Feedback type="invalid" className="feedback-row" dangerouslySetInnerHTML={{ __html: currentPasswordErrorMsg }}/>}
                      </FormGroup>

                      <FormGroup controlId="newPassword" className={`form-group ${newPasswordIsValid === true ? 'valid' : newPasswordIsValid === false ? 'invalid' : ''}`}>
                        <Form.Label>New Password</Form.Label>
                        <div className="d-flex align-items-center justify-content-center">
                          <InputGroup>
                            <Form.Control type={newPasswordShown ? 'text' : 'password'} placeholder="Enter a new password" name="new-password" value={newPassword || ''} autoComplete="new-password"  minLength={passwordPolicy.length.min}
                              className={((passwordUpdateAttempted && !newPassword) || (newPasswordIsValid === false)) ? 'invalid-input' : newPasswordIsValid ? 'is-valid' : ''}
                              onChange={({target: {value}}) => {
                                setNewPassword(value);
                                if (newPasswordIsValid === false) {
                                  setNewPasswordIsValid(null);
                                  setNewPasswordErrorMsg(null);
                                }
                              }} onBlur={() => validateNewPassword()} required />
                            <InputGroup.Text className="password-show-icon-container" onClick={() => setNewPasswordShown(!newPasswordShown)}>
                              {newPasswordShown ? <AiOutlineEye size="20px" className="grey-icon" title="New password visible"/> :
                                <AiOutlineEyeInvisible size="20px" className="grey-icon" title="New password hidden"/>}
                            </InputGroup.Text>
                          </InputGroup>
                          <div className="valid-input-icon"><VscCheck size="30px"/></div>
                        </div>
                        {!newPasswordErrorMsg && <Form.Control.Feedback className="feedback-row feedback-info">
                    Your new password must be at least {passwordPolicy.length.min} characters long, and no more than {passwordPolicy.length.max} characters.
                        </Form.Control.Feedback>}
                        {newPasswordErrorMsg && <Form.Control.Feedback type="invalid"className="feedback-row">{newPasswordErrorMsg}</Form.Control.Feedback>}
                      </FormGroup>

                      <Row className="form-actions-row pt-4">
                        <Col className="p-0">
                          <Button variant="primary" type="submit" className="submit ms-0" onClick={() => setPasswordUpdateAttempted(true)} data-testid="change-password-submit" disabled={impersonating}>Change Password</Button>
                        </Col>
                      </Row>
                    </Form>}

                      {!mainError &&
                    <Form className="w-100 mt-4 pt-4 mfa">
                      {mfaMainErrorMsg &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setMfaMainErrorMsg(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                            <p className="alert-text m-0" dangerouslySetInnerHTML={{ __html: mfaMainErrorMsg }} />
                          </Alert>
                        </Row>
                      }
                      <Row className="form-title-row">
                        <Col className="p-0">
                          <h3>Two-Factor Authentication</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0">
                          <p>Two-Factor Authentication (2FA) adds an extra layer of security to your Zuko account. When you login you will provide your email and password, followed by a secure code from your authenticator app.</p>
                          {(impersonatedUser || currentUser).mfaEnabled && !showMfaSetupModal && <>
                            <p>If you need to refresh your authentication, please first <i>Disable 2FA</i>, then re-enable.</p>
                            <Alert variant="outline-success" className="mb-3">
                              <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                              <p className="alert-text m-0">Two-factor authentication is <strong>enabled</strong>.</p>
                            </Alert></>}
                          {!(impersonatedUser || currentUser).mfaEnabled &&
                            <p>Setting it up is easy with an authenticator app. Click to <i>Enable 2FA</i>, then follow the steps.</p>
                          }
                          {!(impersonatedUser || currentUser).mfaEnabled && disableMfaComplete &&
                            <Alert variant="outline-danger" className="mb-3">
                              <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                              <p className="alert-text m-0">Two-factor authentication has been <strong>disabled</strong>.</p>
                            </Alert>}
                          <p>Note: Zuko's 2FA is not applied if you login with a single sign-on provider such as Google.</p>
                        </Col>
                      </Row>
                      <Row className="form-actions-row pt-3">
                        <Col className={`d-flex p-0`}>
                          {(impersonatedUser || currentUser).mfaEnabled && <Button variant="outline-danger" className="ms-0"
                            onClick={() => setShowMfaDisableModal(true)} data-testid="show-disable-mfa" disabled={impersonating}>Disable 2FA</Button>}
                          {!(impersonatedUser || currentUser).mfaEnabled && <Button variant="primary" className="submit ms-0" onClick={() => {
                            setShowMfaSetupModal(true);
                            setMfaMainErrorMsg(null);
                          }} data-testid="show-setup-mfa" disabled={impersonating}>Enable 2FA</Button>}
                        </Col>
                      </Row>
                    </Form>}
                      <Modal centered show={showMfaSetupModal} onHide={handleCancelMfaSetup} className="mfa-modal mfa-setup" aria-labelledby="two-factor-auth-enable-modal">
                        <Modal.Header>
                          <Modal.Title>Setup Two-Factor Authentication</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <MultiStepProgressBar currentStep={mfaSetupCurrentStep} stepValues={[1,2,3]} />
                          <div className="mt-3 mfa-step-content d-flex flex-column px-2">
                            {mfaSetupCurrentStep === 1 && <>
                              <p>You will need to use an authenticator app to setup multi-factor authentication.</p>
                              <p>You could use an app such as:</p>
                              <ul>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://authy.com">Authy</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://lastpass.com/auth">LastPass Authenticator</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app">Microsoft Authenticator</a></li>
                              </ul>
                              <Modal.Footer className="justify-content-between mt-auto">
                                <Button variant="outline-secondary" className="cancel mx-1" onClick={handleCancelMfaSetup}>Cancel</Button>
                                <Button className="mx-1" onClick={() => setMfaSetupCurrentStep(prev => prev+1)}>Next</Button>
                              </Modal.Footer>
                            </>}

                            {mfaSetupCurrentStep === 2 && <>
                              <div className="d-flex flex-column align-items-center">
                                <p className="mb-0">Open your authenticator app and scan this QR code.</p>
                                <div className="qr-code-container mx-auto d-flex justify-content-center align-items-center">
                                  {!qrCodeLoading && qrCode && <img src={qrCode} alt="QR-code"/>}
                                  {qrCodeLoading && <FaSpinner size="18px" className="spinning-icon" title="Generating QR Code..."/>}
                                </div>
                                <p className="mb-1">Or use a <span className="clickable-text" onClick={() => setShowManualSetupKey(!showManualSetupKey)}>manual setup code{showManualSetupKey ? <FaAngleUp size="14px"/> : <FaAngleDown size="14px"/>}</span></p>
                                {showManualSetupKey && <div className="mx-auto d-flex justify-content-center align-items-center">
                                  {!setupKeyLoading && setupKey && <p className="mb-0">{setupKey}</p>}
                                  {setupKeyLoading && <FaSpinner size="18px" className="spinning-icon" title="Retrieving setup key..."/>}
                                </div>}
                                <Form noValidate onSubmit={handleEnableMfa} className="w-100 mt-3">
                                  <FormGroup controlId="mfa-token" className="form-group text-center">
                                    <Form.Label>Now, enter the 6-digit code generated by the app:</Form.Label>
                                    <Form.Control type="text"
                                      inputMode="numeric"
                                      value={otp || ''}
                                      autoComplete="one-time-code"
                                      className={`verification-code mx-auto text-center ${(otpIsValid === false) ? 'invalid-input' : ''}`}
                                      maxLength={6}
                                      htmlSize={7}
                                      onChange={({target: {value}}) => {
                                        setOtp(value);
                                        if (!otpIsValid) {
                                          setOtpIsValid(null);
                                          setOtpErrorMsg(null);
                                        }
                                      }} required/>
                                    {otpErrorMsg && <Form.Control.Feedback type="invalid"className="feedback-row text-center" data-testid="mfa-token-invalid-feedback">{otpErrorMsg}</Form.Control.Feedback>}
                                  </FormGroup>
                                  <Modal.Footer className="justify-content-between">
                                    <Button variant="outline-secondary" className="cancel mx-1" onClick={handleCancelMfaSetup}>Cancel</Button>
                                    <Button className="mx-1" type="submit" data-testid="enable-mfa-submit">Enable 2FA</Button>
                                  </Modal.Footer>
                                </Form>
                              </div>
                            </>}

                            {mfaSetupCurrentStep === 3 && <>
                              <Alert variant="outline-success">
                                <div className="alert-svg-icon my-auto"><VscCheck size="20px"/></div>
                                <p className="alert-text m-0">Setup complete.</p>
                              </Alert>
                              <p className="mt-3">Your Zuko account is now secured with two-factor authentication. When logging in, you will be asked to input the verification code from your authenticator app.</p>
                              <p>If you lose access to your authenticator app at any time, please contact Zuko support.</p>
                              <Modal.Footer className="justify-content-end mt-auto">
                                <Button className="mx-1" onClick={handleCancelMfaSetup}>Done</Button>
                              </Modal.Footer>
                            </>}
                          </div>
                        </Modal.Body>
                      </Modal>

                      <Modal centered show={showMfaDisableModal} onHide={handleCancelMfaDisable} className="mfa-modal mfa-disable" aria-labelledby="two-factor-auth-disable-modal">
                        <Modal.Header>
                          <Modal.Title>Disable Two-Factor Authentication</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="mfa-step-content d-flex flex-column align-items-center px-2">
                            <p>Removing two-factor authentication will remove the extra security on your Zuko account. Once disabled, you'll only use your email and password to login.</p>
                            <Form noValidate onSubmit={handleDisableMfa} className="w-100">
                              <InputGroup>
                                <Form.Control type={mfaCurrentPasswordShown ? 'text' : 'password'} placeholder="Enter your current password" name="current-password" value={mfaCurrentPassword || ''} autoComplete="current-password"
                                  className={(mfaCurrentPasswordIsValid === false) ? 'invalid-input' : ''}
                                  onChange={({target: {value}}) => {
                                    setMfaCurrentPassword(value);
                                    if (mfaCurrentPasswordIsValid === false) {
                                      setMfaCurrentPasswordIsValid(null);
                                      setMfaCurrentPasswordErrorMsg(null);
                                    }
                                  }} required data-testid="mfa-current-password"/>
                                <InputGroup.Text className="password-show-icon-container" onClick={() => setMfaCurrentPasswordShown(!mfaCurrentPasswordShown)}>
                                  {mfaCurrentPasswordShown ? <AiOutlineEye size="20px" className="grey-icon" title="Current password visible"/> :
                                    <AiOutlineEyeInvisible size="20px" className="grey-icon" title="Current password hidden"/>}
                                </InputGroup.Text>
                              </InputGroup>
                              {mfaCurrentPasswordErrorMsg && <Form.Control.Feedback type="invalid" className="feedback-row" dangerouslySetInnerHTML={{ __html: mfaCurrentPasswordErrorMsg }}/>}
                              <Modal.Footer className="justify-content-between">
                                <Button variant="outline-secondary" className="cancel mx-1" onClick={handleCancelMfaDisable}>Cancel</Button>
                                <Button className="mx-1" variant="danger" type="submit" data-testid="disable-mfa-submit">Disable 2FA</Button>
                              </Modal.Footer>
                            </Form>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </TabPane>
                  </TabContent>
                </Row>
              </TabContainer>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default ProfileEdit;
