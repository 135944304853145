import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '/api',
  withCredentials: true, // Whether to send and receive cookies with requests

  paramsSerializer: (params) => {
    return qs.stringify((() => {
      const newParams = snakecaseKeys(params, { deep: true });
      // Restore the original attributes/filters as we don't want to convert the keys of this hash
      if (params.attributes) newParams.attributes = params.attributes;
      if (params.filter) newParams.filter = params.filter;
      if (params.filters) newParams.filters = params.filters;
      return newParams;
    })(), {arrayFormat: 'brackets'});
  },

  transformResponse: [(responseBody) => {
    if (typeof responseBody === 'string') {
      try {
        responseBody = JSON.parse(responseBody);
      } catch (e) { /* Ignore */ }
    }
    // Don't camel case submissions keys
    if (responseBody?.submissions) return responseBody;

    return camelcaseKeys(responseBody, { deep: true,  stopPaths: ['sessions.attributes', 'session.attributes'] });
  }],

  transformRequest: [(data, headers) => {
    if (data !== null && typeof data === 'object') {
      const newRequest = snakecaseKeys(data, { deep: true, exclude: ['_csrf_token'] });
      // Restore original filters as we don't want to snakecase the keys of this hash
      if (data.filters) newRequest.filters = data.filters;
      return newRequest;
    }
    return data;
  }, ...axios.defaults.transformRequest],
});

let fieldFlowExecutingRequestToken = null;

api.interceptors.request.use(request => {
  // Cancel the field flow request in progress
  if (request.url === '/field-flows' && fieldFlowExecutingRequestToken) {
    fieldFlowExecutingRequestToken.cancel();
    fieldFlowExecutingRequestToken = null;
  }

  // Set the token for the current field flow request
  if (request.url === '/field-flows') {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    request.cancelToken = source.token;
    fieldFlowExecutingRequestToken = source;
  }

  return request;
}, error => Promise.reject(error));

api.interceptors.response.use(response => {
  // Unset the token for the current field flow request
  if (response.config.url === '/field-flows' && fieldFlowExecutingRequestToken) fieldFlowExecutingRequestToken = null;

  return response;
}, error => {
  // Check if this is a cancelled request to drop it silently (without error)
  if (axios.isCancel(error)) return new Promise(() => {});

  // Unset the token for the current field flow request
  if (error.config.url === '/field-flows' && fieldFlowExecutingRequestToken) fieldFlowExecutingRequestToken = null;

  if (error.response && error.response.status === 401 && error.response.config.url !== '/login') {
    localStorage.clear();
    window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;
  }
  return Promise.reject(error);
});

export default api;
