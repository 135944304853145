import qs from 'qs';
import moment from 'moment-timezone';
import arrayMove from 'array-move';
import LoadingIndicator from './Components/Select/LoadingIndicator';
import snakecaseKeys from 'snakecase-keys';
import React from 'react';
import Forms from './forms';
import comparisons from './field_segment_comparisons';

export const getSession = () => {
  try {
    return JSON.parse(localStorage.getItem('zukoAppQuery')) || {};
  } catch (e) {/* Do nothing - no app query */}
};

// Update local storage with the updated session object.
export const updateSession = (session) => {
  localStorage.setItem('zukoAppQuery', JSON.stringify(session));
};

export const formatFiltersForSelected = (filters) =>
  Object.entries(filters)
    .map(([key, values]) => values.map((value) => ({
      key,
      value: `${key}:${value}`,
      label: value,
    })))
    .flat();

export const formatFiltersForSelectDropDown = (input) => {
  const stage1 = input.reduce((acc, { key, value }) => {
    const filter = JSON.stringify({ key, value });
    if (!acc.hasOwnProperty(key)) acc[key] = {};
    if (!acc[key].hasOwnProperty(filter)) acc[key][filter] = value;
    return acc;
  }, {});

  const stage2 = {};
  for (const key in stage1) {
    if (stage1.hasOwnProperty(key)) {
      const values = stage1[key];
      if (!stage2.hasOwnProperty(key))
        stage2[key] = Object.values(values).map((value) => {
          return {
            key,
            value: `${key}:${value}`,
            label: value,
          };
        });
    }
  }

  const groupedAndFormattedFilters = {};
  for (const [label, options] of Object.entries(stage2))
    groupedAndFormattedFilters[label] = { label, options };
  return Object.values(groupedAndFormattedFilters);
};

/**
 * @typedef Props
 * @prop {Object} form
 * @prop {Object} time
 * @prop {Array} filters
 * @prop {Array} [sessionOutcomes]
 * @prop {string} [granularity]
 * @prop {string} [submitFieldIdentifier]
 * @prop {Object} [fieldFlow]
 * @prop {Object} [sessionExplorer]
 * @prop {Object} [sessions]
 */

/**
 * @param {Props} props
 */
export const compileQueryString = ({form, time, granularity, filters, sessionOutcomes, submitFieldIdentifier,
  fieldFlow, sessionExplorer, sessionFilters,
}) => {
  const queryParams = snakecaseKeys({
    form: { uuid: form.uuid },
    ...(time?.start && time?.end) && {
      ts: {
      // In case the timezone is due to change, we need to create new time objects using the provided form's timezone
        start: moment.tz(time.start.format('YYYY-MM-DDTHH:mm:ss'), form?.organisation?.timeZone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        end: moment.tz(time.end.format('YYYY-MM-DDTHH:mm:ss'), form?.organisation?.timeZone).endOf('minute').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      }},
    granularity,
    sessionOutcomes: (sessionOutcomes || []).map(({value}) => value),
    ...(submitFieldIdentifier !== undefined) && {submitFieldIdentifier},
    ...fieldFlow && {fieldFlow},
    ...sessionExplorer && {sessionExplorer: (Object.keys(sessionExplorer).length && sessionExplorer) || null}, // null is required to keep the key in the query string,
    ...sessionFilters && {sessionFilters: (Object.keys(sessionFilters).length && sessionFilters) || null}, // null is required to keep the key in the query string,
  }, {deep: true});

  // NB. Has to be performed after the snake-casing as there isn't a way to exclude/stop at this key
  queryParams.filters = (filters || []).reduce((acc, {key, label: value}) => {
    if (!acc.hasOwnProperty(key)) acc[key] = [];
    acc[key].push(value);
    return acc;
  }, {});
  return qs.stringify(queryParams, { addQueryPrefix: true, strictNullHandling: true });
};

export const compileFormOnlyQueryString = (form) => qs.stringify({form: { uuid: form?.uuid }}, { addQueryPrefix: true });

export const compileFieldComparisonQueryString = ({comparisonUuid, time, metric}) => {
  const { org: { timeZone } = {} } = comparisons.getByUuid(comparisonUuid) || {};
  const queryParams = snakecaseKeys({
    ...(time?.start && time?.end && timeZone) && {
      ts: {
        start: moment.tz(time.start.format('YYYY-MM-DDTHH:mm:ss'), timeZone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        end: moment.tz(time.end.format('YYYY-MM-DDTHH:mm:ss'), timeZone).endOf('minute').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      }
    },
    fieldSegmentComparison: {
      comparisonUuid,
      ...metric && {metric},
    }
  }, { deep: true });
  return qs.stringify(queryParams, { addQueryPrefix: true });
};

const formatRoundSeconds = (seconds) => Math.round(seconds) >= 10 ? Math.round(seconds) : Math.round((seconds + Number.EPSILON) * 10) / 10;

const formatDecimalSeconds = (seconds) => (Math.round(seconds * 10) / 10).toLocaleString();

export const formatMillisecondsToTimeString = (ms) => {
  const d = ms/1000;
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  if (h > 0) {
    return `${h}h ${m}m ${formatRoundSeconds(s)}s`;
  } else if (m > 0) {
    return `${m}m ${formatRoundSeconds(s)}s`;
  } else {
    return `${formatDecimalSeconds(d)}s`;
  }
};

export const formatSecondsToTimeString = (d) => {
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  if (h > 0) {
    return `${h}h ${m}m ${formatRoundSeconds(s)}s`;
  } else if (m > 0) {
    return `${m}m ${formatRoundSeconds(s)}s`;
  } else {
    return `${formatDecimalSeconds(d)}s`;
  }
};

export const formatCellToStr = (cell) => (cell.value === null || cell.value === undefined) ? null : cell.value.toLocaleString(undefined, {maximumFractionDigits: 2});

export const formatCellToStrWithPercent = (cell) => (cell.value === null || cell.value === undefined) ? null : cell.value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';

export const formatCellToSeconds = (cell) => (cell.value === null || cell.value === undefined || cell.value === 'N/A') ? null : formatMillisecondsToTimeString(cell.value);

export const formatCellToTime = (cell) => (cell.value === null || cell.value === undefined || cell.value === 'N/A') ? null : moment(cell.value).format('llll');

export const chunk = (arr, size) => {
  const output = [];
  for (let i = 0; i < arr.length; i += size) output.push(arr.slice(i, i + size));
  return output;
};

export const labelForField = ({label, htmlName, htmlId, htmlType, htmlTagName} = {}) => label || htmlName || htmlId || htmlTagName + (htmlType && ' ' + htmlType) || 'Unlabelled';

export const identifierForField = ({htmlTagName, htmlType, htmlName, htmlId}) => `["${htmlTagName}","${htmlType}","${htmlName}","${htmlId}"]`;

export const htmlAttrsForIdentifier = (identifier) => {
  try {
    const [htmlTagName, htmlType,  htmlName, htmlId] = JSON.parse(identifier);
    return {htmlTagName, htmlType,  htmlName, htmlId};
  } catch (e) {
    // Likely a merged field uuid
    return {};
  }
};

export const defaultAttributes = ['browserFamily', 'deviceType', 'Visitor Type', 'autofillTriggered', 'trafficMedium'];

export const formatFormSelectOptions = ({formsGroupedByOrg, selectedForm, currentForm, formsLoading,
  formsLoadingError}) => {
  if (formsLoadingError) {
    return [];
  } else if (formsGroupedByOrg) {
    const orgUuids = Object.keys(formsGroupedByOrg);
    let forms = Object.values(formsGroupedByOrg);

    // Position the current org at the top of the list
    const form = (currentForm || selectedForm);
    const currentOrg = form?.organisation;
    if (form && currentOrg?.uuid) {
      const currentOrgIndex = orgUuids.indexOf(currentOrg.uuid);
      forms = arrayMove(forms, currentOrgIndex, 0);
    }

    return forms.map(({name, uuid, forms}) => ({
      label: name,
      options: (forms || []).map((form) => ({
        value: form.uuid,
        label: form.label,
        orgUuid: uuid,
        orgLabel: name,
      }))
    }));
  } else if (formsLoading) {
    return [{selectable: false, value: null, label: <LoadingIndicator/>}];
  }
};

export const stringToSnakeCase = (str) => str?.replace(/[A-Z]/g, (letter, i) =>  i === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`);

export const formatToPercentOneDecimal = (val) => (val !== null && val !== undefined) && (val * 100).toLocaleString(undefined, {maximumFractionDigits: 1}) + '%';

export const formatPercentToOneDecimal = (val) => (val !== null && val !== undefined) ? val.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%' : val;
export const formatToString = (val) => (val !== null && val !== undefined) ? val.toLocaleString() : val;

export const timeOfPreviousPeriod = ({start, end}) => {
  const daysDiff = end.diff(start, 'days') + 1;
  const msDiff = daysDiff * 60 * 60 * 24;

  return {
    start: start.subtract(msDiff, 'seconds'),
    end: end.subtract(msDiff, 'seconds'),
  };
};

export const calcPercentDiff = (curr, prev) => {
  const diff = ((curr - prev) / prev) * 100;
  return (Number.isNaN(diff) || diff === Infinity) ? null : diff;
};

/**
 * TODO: Move
 * @param handleCreateNewComparison Callback to call to create the comparison
 * @param formUuid
 * @returns {JSX.Element}
 * @constructor
 */
const CreateNewComparisonListEntry = ({handleCreateNewComparison, formUuid}) => (
  <div className="add-comparison-link">
    <span onClick={() => handleCreateNewComparison({formUuid})}>+ Add New Comparison</span>
  </div>
);

export const formatFieldSegmentComparisonSelectOptions = ({
  currentFieldSegmentComparisonOrgUuid,
  fieldSegmentComparisonsLoading, fieldSegmentComparisonsLoaded,
  fieldSegmentComparisonsLoadingError, fieldSegmentComparisonsByFormUuid,
  formsGroupedByOrg,
  handleCreateNewComparison,
}) => {
  if (fieldSegmentComparisonsLoadingError) {
    return [];
  } else if (fieldSegmentComparisonsLoading) {
    return [{selectable: false, value: null, label: <LoadingIndicator/>}];
  } else if (fieldSegmentComparisonsLoaded && comparisons.length && Forms.length > 0 && formsGroupedByOrg) {

    let orgs = Object.values(formsGroupedByOrg);
    const orgUuids = Object.keys(formsGroupedByOrg);

    // Position the current org optgroup at the top of the list
    if (currentFieldSegmentComparisonOrgUuid) {
      const currentOrgIndex = orgUuids.indexOf(currentFieldSegmentComparisonOrgUuid);
      orgs = arrayMove(orgs, currentOrgIndex, 0);
    }

    return orgs
      .map((org) => (org.forms && Object.values(org.forms).length) && ({
        label: org.name,
        level: 0,
        options: Object.values(org.forms).reduce((acc, form) => {
          const comparisons = (fieldSegmentComparisonsByFormUuid[form.uuid]?.comparisons && Object.values(fieldSegmentComparisonsByFormUuid[form.uuid]?.comparisons)) || [];
          acc.push({
            label: form.label,
            formUuid: form.uuid,
            orgName: org.name,
            orgUuid: org.uuid,
            level: 1,
            selectable: false,
            ...comparisons?.length && {comparisonsCount: comparisons.length},
          });
          for (const comparison of comparisons) {
            acc.push({
              ...comparison,
              formUuid: form.uuid,
              formLabel: form.label,
              orgName: org.name,
              orgUuid: org.uuid,
              label: comparison.name,
              value: comparison.uuid,
              level: 2
            });
          }
          acc.push({
            selectable: false,
            formUuid: form.uuid,
            formLabel: form.label,
            orgName: org.name,
            orgUuid: org.uuid,
            label: <CreateNewComparisonListEntry
              handleCreateNewComparison={handleCreateNewComparison}
              formUuid={form.uuid}
            />,
            value: null,
            level: 2,
          });
          return acc;
        }, [])
      }));
  }
};

export const msTimestampToDate = ({msTimestamp, timeZone}) => {
  if (msTimestamp && timeZone) return moment(msTimestamp).tz(timeZone).format('D MMM YYYY');
};

export const getCurrencySymbolFromCode = (code) => {
  switch (code?.toLowerCase()) {
  case 'gbp':
    return '£';
  case 'usd':
    return '$';
  case 'eur':
    return '€';
  default:
    return null;
  }
};

export const formatToCurrencyWithDecimals = ({currencyCode, decimalPlaces, value}) => {
  return `${getCurrencySymbolFromCode(currencyCode)}${value.toLocaleString(undefined, {maximumFractionDigits: decimalPlaces})}`;
};

export const progressIntervalId = ({setFn, progress = 20, interval = 100}) => setInterval(() =>
  setFn((prevProgress) => prevProgress <= 100 ? prevProgress + progress : prevProgress), interval);

export const fixedAttributeFormatter = ({filters, fixedAttribute}) => {
  return (filters || []).concat(fixedAttribute).reduce((acc, {key, label: value}) => {
    if (!acc.hasOwnProperty(key)) acc[key] = [];
    if (!acc[key].includes(value)) acc[key].push(value);
    return acc;
  }, {});
};

export const splitName = (name) => name?.match(/([^\s]+)\s?(.*)/) || name;
