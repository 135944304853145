import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Select from 'react-select';
import moment from 'moment-timezone';
import {
  LineController, LineElement, PointElement, LinearScale, TimeSeriesScale, // For time series line charts
  Tooltip, Legend // Additional utils
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import ProgressBar from 'react-bootstrap/ProgressBar';
import TimePicker from 'react-time-picker';
import addrs from 'email-addresses';
import qs from 'qs';
import { formatCellToStr } from '../utils';

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import DatePicker from '../Components/DatePicker';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import api from '../api';

ReactChart.register(LineController, LineElement, PointElement, LinearScale, Tooltip, Legend, TimeSeriesScale, annotationPlugin);

const alertTypes = {
  eventCount: 'event_count',
  sessionMetric: 'session_metric',
};
const alertOptions = [
  {value: 'completion', label: 'Completion Count', type: alertTypes.eventCount},
  {value: 'abandon', label: 'Abandon Count', type: alertTypes.eventCount},
  {value: 'completion_rate', label: 'Completion Rate (%)', type: alertTypes.sessionMetric},
  {value: 'abandon_rate', label: 'Abandon Rate (%)', type: alertTypes.sessionMetric},
];
const alertComparators = [{value: '<', label: 'less than'}, {value: '>', label: 'more than'}];

const AlertConfig = ({mixpanel, configType}) => {
  const {uuid: alertUuid} = useParams();
  const location = useLocation();
  const { currentUser, timeZone, setCurrentOrg } = useContext(AppContext);
  const history = useHistory();

  const [form, setForm] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [alertOption, setAlertOption] = useState(alertOptions[0]);
  const [chartData, setChartData] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartProgress, setChartProgress] = useState(20);
  const [chartError, setChartError] = useState(null);
  const [threshold, setThreshold] = useState('');
  const [comparator, setComparator] = useState({value: '<', label: 'less than'});
  const [thresholdError, setThresholdError] = useState(null);
  const [alertFrequency, setAlertFrequency] = useState('alert-anytime');
  const [alertBetweenFrom, setAlertBetweenFrom] = useState('08:00');
  const [alertBetweenTo, setAlertBetweenTo] = useState('18:00');
  const [recipients, setRecipients] = useState('');
  const [recipientsError, setRecipientsError] = useState(null);
  const [enabled, setEnabled] = useState(true);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(null);
  const [saveErrors, setSaveErrors] = useState(null);
  const [time, setTime] = useState({start: null, end: null});
  const [alert, setAlert] = useState({});
  const [alertLoading, setAlertLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [deleteAlertError, setDeleteAlertError] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  // Fetch requested form for add alert
  useEffect(() => {
    const loadFormWithOrg = async (formUuid) => {
      try {
        setFormLoading(true);
        setFormError(null);

        const { data: { form } } = await api.get(`/forms/${formUuid}`);
        setForm(form);
        if (form.organisation) setCurrentOrg(form.organisation);
        setFormLoading(false);
      } catch (e) {
        setFormLoading(false);
        setFormError((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };

    if (configType === 'add') {
      const { form: requestedForm = {} } = qs.parse(location.search, { ignoreQueryPrefix: true });
      loadFormWithOrg(requestedForm.uuid);
    }
    if (configType === 'edit' && alert && alert.form && alert.form.uuid) loadFormWithOrg(alert.form.uuid);
  }, [location.search, configType, alert, setCurrentOrg]);

  // Fetch requested alert for edit alert
  useEffect(() => {
    const loadAlert = async () => {
      try {
        setAlertLoading(true);
        setAlertError(null);
        const { data: { alert } } = await api.get(`/alerts/${alertUuid}`);
        setAlert(alert);
        setAlertLoading(false);
      } catch (e) {
        setAlertLoading(false);
        setAlertError((e.response && (e.response.status === 404)) ? 'Alert not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };

    if (configType === 'edit' && alertUuid) loadAlert();
  }, [configType, alertUuid]);

  // Alert received so populate settings
  useEffect(() => {
    if (Object.keys(alert).length) {
      setAlertOption(alertOptions.find((e) => {
        if (alert.event) {
          return e.value === alert.event;
        } else {
          return e.value === alert.metric;
        }
      }));
      setComparator(alertComparators.find((c) => c.value === alert.comparator));
      setThreshold(alert.threshold);
      if (alert.activeFrom && alert.activeTo) {
        setAlertFrequency('alert-between');
        setAlertBetweenFrom(alert.activeFrom);
        setAlertBetweenTo(alert.activeTo);
      }
      setEnabled(alert.enabled);
      setRecipients(alert.recipients.map((r) => r.email).join(','));
    }
  }, [alert]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  useEffect(() => {
    if (form && configType) {
      mixpanel.track('Page View', { page: `Alerts - ${configType}`, ...(form.organisation && form.organisation.uuid) && {
        'Organisation Name': form.organisation.name,
        'Organisation Uuid': form.organisation.uuid,
        'Organisation Contract Type': form.organisation.contractType,
      }});
    }
  }, [mixpanel, form, configType]);

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: `Alerts - ${configType}`,
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
    });
    setTime({start, end});
  };

  useEffect(() => {
    if (timeZone) setTime({
      start: moment.tz(timeZone).subtract(30, 'days').startOf('day'),
      end: moment.tz(timeZone).subtract(1, 'days').endOf('day'),
    });
  }, [timeZone]);

  useEffect(() => {
    const loadChartData = async () => {
      const progressID = setInterval(() => setChartProgress((prevProgress) => prevProgress + 30), 50);
      try {
        setChartLoading(true);
        setChartData(null);
        setChartError(null);

        let data;
        if (alertOption.type === alertTypes.eventCount) {
          const resp = await api.get('/events', {
            params: {
              eventTypes: [alertOption.value],
              form: {uuid: form.uuid},
              timePeriod: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
              },
              granularity: 'hour',
              timeZone,
            },
          });
          data = resp.data;
        } else {
          const resp = await api.get('/data/sessions/stats', {
            params: {
              metric: alertOption.value,
              formUuid: form.uuid,
              timePeriod: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
              },
              dimension: 'time',
              granularity: 'hour',
              timeZone,
              timeBucketFormat: 'epoch_millis',
            },
          });
          data = resp.data;
        }

        setChartProgress(100);
        const isData = (data && data.labels.length > 0);
        if (!isData) {
          setChartError('No data to display');
        } else {
          data.datasets = data.datasets.map((d) => {
            return {
              ...d,
              label: d.label || alertOptions.find((opt) => d.metric === opt.value)?.label,
              backgroundColor: d.label === 'Completions' || d.metric?.match(/completion/) ? '#0267BF' : '#F0764A',
              borderColor:  d.label === 'Completions' || d.metric?.match(/completion/) ? '#0267BF' : '#F0764A',
              fill: false,
              borderWidth: 1,
              pointRadius: 1,
            };
          });
          setChartData(data);
        }
        setChartLoading(false);
        setChartProgress(0);
        clearInterval(progressID);
      } catch (e) {
        setChartProgress(100);
        setChartError((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
        setChartLoading(false);
        setChartProgress(0);
        clearInterval(progressID);
      }
    };

    if (form && form.uuid && time?.start && time?.end) loadChartData();

  }, [alertOption, form, time, timeZone]);

  const handleCancel = () => {
    history.push(`/organisations/${form.organisation.uuid}/alerts`);
  };

  const saveAddAlert = async () => {
    try {
      setSaveErrors(null);
      await api.post('/alerts', {
        alert: {
          ...alertOption.type === alertTypes.eventCount && {event: alertOption.value},
          ...alertOption.type === alertTypes.sessionMetric && {metric: alertOption.value},
          comparator: comparator.value,
          threshold,
          ...alertFrequency === 'alert-between' && {
            activeFrom: alertBetweenFrom, activeTo: alertBetweenTo
          },
          enabled,
          form: {uuid: form.uuid},
          recipients: recipients.split(',').map((r) => r.trim())
        }
      });
      setSaveSuccessMessage('Alert saved.');
      mixpanel.track('Saved Alert', { page: `Alerts - ${configType}` });
    } catch (e) {
      if ((e.response && (e.response.status === 422) && e.response.data && e.response.data.errors)) {
        setSaveErrors(e.response.data.errors.map(({message}) => message));
      } else {
        setSaveErrors(['Something went wrong saving the alert.']);
      }
    }
  };

  const saveEditAlert = async () => {
    try {
      setSaveErrors(null);
      await api.put(`/alerts/${alert.uuid}`, {
        alert: {
          ...alertOption.type === alertTypes.eventCount && {event: alertOption.value},
          ...alertOption.type === alertTypes.sessionMetric && {metric: alertOption.value},
          comparator: comparator.value,
          threshold,
          ...alertFrequency === 'alert-between' && {
            activeFrom: alertBetweenFrom, activeTo: alertBetweenTo
          },
          ...alertFrequency === 'alert-anytime' && {
            activeFrom: null, activeTo: null
          },
          enabled,
          form: {uuid: form.uuid},
          recipients: recipients.split(',').map((r) => r.trim())
        }
      });
      setSaveSuccessMessage('Alert saved.');
      mixpanel.track('Saved Alert', { page: `Alerts - ${configType}` });
    } catch (e) {
      if ((e.response && (e.response.status === 422) && e.response.data && e.response.data.errors)) {
        setSaveErrors(e.response.data.errors.map(({message}) => message));
      } else {
        setSaveErrors(['Something went wrong saving the alert.']);
      }
    }
  };

  const handleSave = async (e) => {
    if (saveDisabled) return;
    setSaveDisabled(true);
    e.preventDefault();

    let alertValid = true;

    // Check threshold and recipients are valid
    if (!threshold || parseInt(threshold) < 1) {
      alertValid = false;
      setThresholdError('Threshold value must be at least 1');
    }

    if (!recipients) {
      alertValid = false;
      setRecipientsError('Must enter at least one recipient');
    } else if (recipients.split(',').length > 25) {
      alertValid = false;
      setRecipientsError('Enter a maximum of 25 valid email addresses');
    } else if (addrs.parseAddressList(recipients) === null) {
      alertValid = false;
      setRecipientsError('Valid email addresses must be separated by a comma');
    }

    try {
      if (alertValid && configType === 'add') await saveAddAlert();
      if (alertValid && configType === 'edit' && alert.uuid) await saveEditAlert();
    } finally {
      setSaveDisabled(false);
    }
  };

  const handleDelete = async () => {
    if (deleteDisabled) return;
    setDeleteDisabled(true);
    try {
      await api.delete(`/alerts/${alert.uuid}`);
      mixpanel.track('Deleted Alert', { page: `Alerts - ${configType}` });
      history.push(`/organisations/${form.organisation.uuid}/alerts`);
    } catch (e) {
      setDeleteAlertError('Something went wrong. Alert could not be deleted.');
      setDeleteDisabled(false);
    }
  };

  // Once saved, show the success message briefly, then return to main Alerts page
  useEffect(() => {
    if (saveSuccessMessage && form.organisation && form.organisation.uuid) {
      setTimeout(() => {
        setSaveSuccessMessage(null);
        history.push(`/organisations/${form.organisation.uuid}/alerts`);
      }, 1000);
    }

  }, [saveSuccessMessage, form, history]);

  return (
    <Container fluid className="page" id="alerts-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Alerts</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={`Alerts - ${configType}`}
            org={form?.organisation}
            messageContent={configType === 'add' ? 'adding an Alert' : 'editing an Alert'} />
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1 id="alerts-title">
                {form && form.label && `${form.label} | Alert Configuration`}
              </h1>
            </Col>
          </Row>
          <Col className="p-0">
            <Card>
              <Card.Body className="pb-0">
                <Row className="g-0 card-title-row">
                  <Col className="p-0">
                    <Card.Title as="h3">{configType === 'add' ? 'Add Alert' : configType === 'edit' && 'Edit Alert'}</Card.Title>
                  </Col>
                </Row>
                <div className="card-vis">
                  <Row className="g-0 justify-content-between">
                    <Col className="ps-0 col-auto" id="datepicker">
                      {time?.start && time?.end &&
                        <DatePicker
                          startTime={time?.start}
                          endTime={time?.end}
                          onApply={handleDateTimeRangeChange}
                          minDate={moment.tz(timeZone).subtract(184, 'days').startOf('day')} // 6 months
                        />}
                    </Col>
                    <Col md={4} className="pe-0 d-flex align-items-center flex-row justify-content-end" id="event-type-select">
                      <Form.Label className="me-2 mb-0">Event type:</Form.Label>
                      <Select
                        styles={{
                          control: (styles, state) => ({...styles,
                            border: '1px solid #EBEDF2',
                            width: '180px',
                          }),
                          option: (styles, state) => ({...styles,
                            color: '#3f4047',
                            backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                            '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                          }),
                          menu: (styles, state) => ({...styles,
                            marginTop: '1px',
                            borderRadius: '4px',
                            border: '1px solid #EBEDF2',
                            boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                          }),
                          dropdownIndicator: (styles, state) => ({...styles,
                            cursor: 'pointer',
                            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                            transition: 'transform .5s ease',
                          }),
                        }}
                        options={alertOptions}
                        onChange={(e) => {setAlertOption(e); setSaveErrors(null);}}
                        value={alertOption}
                        isSearchable={false}
                      />
                    </Col>
                  </Row>
                  {(formLoading || alertLoading) && <p>Loading...</p>}
                  {formError && <p className="text-center">{formError}</p>}
                  {alertError && <p className="text-center">{alertError}</p>}
                  {chartData ? <>
                    <div data-testid="alerts-chart-wrapper">
                      <ReactChart
                        type="line"
                        data={chartData}
                        width={1216}
                        height={350}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            y: {
                              title: {
                                text: alertOption.value.includes('rate') ? 'Rate' : 'Count',
                                display: true,
                              },
                              type: 'linear',
                              ticks: {
                                callback: (value) => formatCellToStr({value}) + (alertOption.value.includes('rate') ? '%' : ''),
                              },
                            },
                            x: {
                              title: {
                                text: 'Hour',
                                display: true,
                              },
                              type: 'timeseries',
                              time: {
                                unit: 'hour',
                                displayFormats: {
                                  hour: chartData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                },
                                isoWeekday: true,
                                tooltipFormat: 'MMM D, HH:mm',
                              },
                            },
                          },
                          elements: {
                            line: {
                              tension: 0.05
                            }
                          },
                          interaction: {
                            intersect: false,
                            mode: 'nearest',
                          },
                          plugins: {
                            legend: {
                              labels: {
                                boxWidth: 20
                              }
                            },
                            tooltip: {
                              position: 'nearest',
                              callbacks: {
                                label: (context) => {
                                  return `${context.dataset.label}: ${context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
                                },
                              },
                            },
                            annotation: {
                              annotations: [
                                {
                                  type: 'line',
                                  yMin: threshold,
                                  yMax: threshold,
                                  borderColor: '#e01f46',
                                  borderWidth: 1,
                                },
                              ],
                            },
                          }
                        }}
                      />
                    </div>
                    {chartData.datasets && chartData.datasets.map((d, i) => {
                      const {min, max, mean} = d.thresholds;
                      return (max !== null || min !== null || mean !== null) &&
                      <div className="chartThresholds text-center" key={`threshold-${i}`}>
                        <div className="col-md-4">
                          <span className="thresholdMetric">High: </span>
                          <span className="thresholdFigure">{formatCellToStr({value: max})}{alertOption.value.includes('rate') && '%'}</span>
                        </div>
                        <div className="col-md-4">
                          <span className="thresholdMetric">Mean: </span>
                          <span className="thresholdFigure">{formatCellToStr({value: mean})}{alertOption.value.includes('rate') && '%'}</span>
                        </div>
                        <div className="col-md-4">
                          <span className="thresholdMetric">Low: </span>
                          <span className="thresholdFigure">{formatCellToStr({value: min})}{alertOption.value.includes('rate') && '%'}</span>
                        </div>
                      </div>;
                    })} </>
                    : chartError ?
                      <p className="text-center" data-testid="alerts-chart-error">{chartError}</p>
                      : chartLoading &&
                <div className="progress-area">
                  <ProgressBar animated now={chartProgress}/>
                </div>
                  }
                  {!formError && form.organisation && form.organisation.uuid &&
                    <div className="alert-settings">
                      <Form>
                        <Row className="g-0 justify-content-start align-items-center">
                          <Col className="p-0 d-flex align-items-center">
                            <Form.Label className="me-2 mb-0" htmlFor="thresholdInput">
                            Alert me when {alertOption.label.toLowerCase()} is
                            </Form.Label>
                            <div id="comparator-select">
                              <Select
                                styles={{
                                  control: (styles, state) => ({...styles,
                                    border: '1px solid #ced4da',
                                    width: '140px',
                                  }),
                                  option: (styles, state) => ({...styles,
                                    color: '#3f4047',
                                    backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                                  }),
                                  menu: (styles, state) => ({...styles,
                                    marginTop: '1px',
                                    borderRadius: '4px',
                                    border: '1px solid #EBEDF2',
                                    boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                  }),
                                  dropdownIndicator: (styles, state) => ({...styles,
                                    cursor: 'pointer',
                                    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                    transition: 'transform .5s ease',
                                  }),
                                }}
                                options={alertComparators}
                                onChange={(e) => setComparator(e)}
                                value={comparator}
                                isSearchable={false}
                              />
                            </div>
                            <Form.Control className={`form-control align-middle ${thresholdError && 'invalid-input'}`} id="thresholdInput" type="number" required value={threshold} min={1} onChange={({target: {value}}) => {setThreshold(value); setThresholdError(null);}}/>
                            <span className="d-inline-block">per hour.</span>
                            {thresholdError && (<p className="error m-0">{thresholdError}</p>)}
                          </Col>
                        </Row>
                        <Row className="g-0 justify-content-start pt-2">
                          <Col className="p-0 d-flex align-items-center">
                            <fieldset>
                              <FormGroup className="form-group mb-0" controlId="alert-frequency">
                                {[{label: 'Alert me all hours', value: 'alert-anytime'}, {label:'Alert me between', value: 'alert-between'}].map(({label, value}) => (
                                  <Form.Check className={value !== alertFrequency && 'disabled-alert-frequency'} inline type="radio" label={label} id={value} value={value} data-testid={`${value}-radio`}
                                    checked={value === alertFrequency} onChange={(e) => setAlertFrequency(e.target.value)} key={value}/>
                                ))}
                              </FormGroup>
                            </fieldset>
                            <div id="alert-between-time" className="d-flex align-items-stretch">
                              <TimePicker
                                onChange={setAlertBetweenFrom}
                                value={alertBetweenFrom}
                                disableClock={true}
                                disabled={alertFrequency === 'alert-anytime'}
                                clearIcon={null}
                                format={'HH:mm'}
                              />
                              <TimePicker
                                onChange={setAlertBetweenTo}
                                value={alertBetweenTo}
                                disableClock={true}
                                disabled={alertFrequency === 'alert-anytime'}
                                clearIcon={null}
                                format={'HH:mm'}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-0 justify-content-start align-items-center pt-2">
                          <Col className="p-0 d-flex align-items-center">
                            <Form.Label className="me-2 mb-0" htmlFor="emailInput">Send alert to</Form.Label>
                            <Form.Control className={`form-control align-middle ms-0 ${recipientsError && 'invalid-input'}`} id="emailInput" type="email" multiple required value={recipients} onChange={({target: {value}}) => {setRecipients(value); setRecipientsError(null);}} placeholder="me@example.com,alert@example.com"/>
                            {recipientsError && (<p className="error m-0">{recipientsError}</p>)}
                          </Col>
                        </Row>
                        <Row className="g-0 justify-content-start align-items-center pt-2">
                          <Form.Check  className={!enabled && 'disabled-alert-status'} type="checkbox" checked={enabled} label="Enabled" onChange={(e) => setEnabled(e.target.checked)} data-testid="alert-enabled-check"/>
                        </Row>
                        {saveErrors && saveErrors.map((e) => (<p className="error m-0">{e}</p>))}
                      </Form>
                    </div>}
                </div>
                <Row className="g-0 justify-content-between card-bottom-tools">
                  {!formError && form.organisation && form.organisation.uuid && <>
                    <Col md={{ order: 'first' }} xs={{ order: 'last' }} className="col-md-3 ps-0 pt-2 pt-md-0">
                      {configType === 'edit' && <Button variant="outline-danger" className="ms-0" onClick={handleDelete} disabled={deleteDisabled} data-testid="delete-alert">Delete</Button>}
                    </Col>
                    <Col className="text-end p-0">
                      <Button variant="outline-secondary" className="cancel me-1 ms-0" type="reset" onClick={handleCancel}>Cancel</Button>
                      <Button variant="primary" className="submit ms-1 me-0" type="submit" onClick={handleSave} data-testid="save-alert-config" disabled={saveDisabled}>Save</Button>
                    </Col>
                  </>}
                </Row>
                {(deleteAlertError || saveSuccessMessage) &&
                  <Row className="g-0 text-end justify-content-between pb-3">
                    {!formError && form.organisation && form.organisation.uuid && <>
                      <Col md={{ order: 'first' }} xs={{ order: 'last' }} className="col-md-3 ps-0 pt-2 pt-md-0">
                        {deleteAlertError && <p className="error-text m-auto">{deleteAlertError}</p>}
                      </Col>
                      <Col className="text-end p-0">
                        {saveSuccessMessage && <span className="m-0"><i className="fa fa-check success-tick-icon"/>{saveSuccessMessage}</span>}
                      </Col>
                    </>}
                  </Row>}
              </Card.Body>
            </Card>
          </Col>
        </Col>
      </div>
    </Container>
  );
};

export default AlertConfig;
